@import "./variables.scss";
@import "./typography.scss";
@import "./bootstrap-grid.min.css";
@import "./pulse.scss";
@import "./beatfi.scss";
@import "./mep.css";

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

html {
  scroll-behavior: smooth;
  background-color: #000 !important;
}

#root {
  height: 100%;
  background-color: #000 !important;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: var(--font-sforotext);
  font-weight: 400;
  overscroll-behavior: none;
  background-color: #000 !important;
}

a {
  color: #ffffff !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.darkLink {
  color: #000;
  text-decoration: none;
}
.darkLink:hover {
  color: #000;
  text-decoration: none;
}

.gradtext {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff8a00),
    to(#da1b60)
  );
  background-clip: border-box;
  background: linear-gradient(to right, #ff8a00, #da1b60);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
  font-size: x-large;
}

.white-text {
  color: #fff;
}
.color, .colour {
  color: #1ed891;
}
.italic {
  font-style: italic;
}

.center { margin:0 auto; }
.text-center { text-align: center; }

/* PADDING */
.pad90 { padding-top: 90px; }
.pad60 { padding-top: 60px; }
.pad45 { padding-top: 45px; }
.pad35 { padding-top: 35px; }
.pad30 { padding-top: 30px; }
.pad25 { padding-top: 25px; }
.pad20 { padding-top: 20px; }
.pad15 { padding-top: 15px; }
.pad10 { padding-top: 10px; }
.pad5 { padding-top: 5px; }
.marg-right10 { margin-right: 10px; }
.marg-top0 { margin-top: 0px; }
.marg-top30 { margin-top: 30px; }
.marg-top-minus25 { margin-top: -25px; }
.marg-0auto { margin: 0 auto; }

/* GAPS */
.gap,
.gap-1,
.gap-2,
.gap-3,
.gap-4,
.gap-5,
.gap-6 {
    display: block;
    height: 20px;
    &::after,
    &::before {
        content: "";
        display: table;
        clear: both;
    }
}
.gap-1 {
    height: 30px;
}
.gap-2 {
    height: 40px;
}
.gap-3 {
    height: 60px;
}
.gap-4 {
    height: 80px;
}
.gap-5 {
    height: 100px;
}
.gap-6 {
    height: 120px;
}


/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color:#fff; line-height: 1.1; }
h1, .h1 {
  font-size: 40px;
}
h2, .h2 { font-size: 28px; }
h3, .h3 { font-size: 24px; }
h4, .h4 { font-size: 20px; }
h5, .h5 { font-size: 18px; }
h6, .h6 { font-size: 16px; }  

.wordsplit {
  word-break: break-all;
}
