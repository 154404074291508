
*:focus {
  outline: 0 !important; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0; }

a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }

button {
  cursor: pointer;
  color: inherit; }

pre {
  color: inherit;
  background-color: #f8f8f8;
  border-color: rgba(120, 130, 140, 0.13); }

code {
  background-color: rgba(120, 130, 140, 0.13); }

blockquote {
  border-color: rgba(120, 130, 140, 0.13); }

small {
  font-size: 90%; }

sup {
  position: relative;
  top: -1em;
  font-size: 75%; }

.pull-center {
  position: absolute;
  left: 50%; }

.close {
  font-size: 1.3rem; }

.img-responsive {
  width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.center-block {
  margin: 0 auto; }

@media (max-width: 767px) {
  .pull-none-sm {
    float: none !important; } }

@media (max-width: 543px) {
  .pull-none-xs {
    float: none !important; } }

.label {
  display: inline-block;
  padding: .25em .5em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: bold;
  background-color: rgba(120, 130, 140, 0.6);
  color: #fff;
  border-radius: .2rem; }
  .label.no-bg {
    background-color: transparent;
    color: inherit; }

.label.up {
  position: relative;
  top: -10px;
  min-width: 4px;
  min-height: 4px;
  display: inline-block; }

.label-lg {
  font-size: 1.33rem;
  padding: 5px 9px; }

.label-sm {
  padding: 1px 4px; }

.label-xs {
  padding: 0;
  width: 8px;
  height: 8px;
  border-radius: 8px; }

.list-group-item.active > .label,
.nav-pills > .active > a > .label {
  color: rgba(0, 0, 0, 0.87); }

.btn .label {
  position: relative;
  top: -1px; }

.btn {
  font-weight: 500;
  outline: 0 !important;
  border-width: 0;
  padding: 0.4375rem 1rem; }
  .btn:not([disabled]):hover, .btn:not([disabled]):focus, .btn:not([disabled]).active {
    box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2); }
  .btn > i.pull-left, .btn > i.pull-right {
    line-height: 1.5; }
  .btn.rounded {
    padding-left: 1.2em;
    padding-right: 1.2em; }

.btn-lg {
  padding: .8125rem 1.75rem; }

.btn-sm {
  padding: .3445rem .75rem; }

.btn-xs {
  padding: .1565rem .5rem;
  font-size: 0.8rem; }

.btn-outline {
  border-width: 1px;
  background-color: transparent;
  box-shadow: none !important; }

.btn.b-primary:hover, .btn.b-primary:focus, .btn.primary:hover, .btn.primary:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #02b875; }

.btn.b-accent:hover, .btn.b-accent:focus, .btn.accent:hover, .btn.accent:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #a88add; }

.btn.b-warn:hover, .btn.b-warn:focus, .btn.warn:hover, .btn.warn:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #fcc100; }

.btn.b-success:hover, .btn.b-success:focus, .btn.success:hover, .btn.success:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6cc788; }

.btn.b-info:hover, .btn.b-info:focus, .btn.info:hover, .btn.info:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6887ff; }

.btn.b-warning:hover, .btn.b-warning:focus, .btn.warning:hover, .btn.warning:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #f77a99; }

.btn.b-danger:hover, .btn.b-danger:focus, .btn.danger:hover, .btn.danger:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #ef1960; }

.btn.b-dark:hover, .btn.b-dark:focus, .btn.dark:hover, .btn.dark:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #1c202b; }

.btn.b-black:hover, .btn.b-black:focus, .btn.black:hover, .btn.black:focus {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #17171b; }

.btn.b-white:hover, .btn.b-white:focus {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffffff; }

.btn.b-light:hover, .btn.b-light:focus {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f8f8f8; }

.btn-icon {
  text-align: center;
  padding: 0 !important;
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  position: relative;
  overflow: hidden; }
  .btn-icon i {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    line-height: inherit;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-icon.btn-xs {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem; }
  .btn-icon.btn-sm {
    width: 1.775rem;
    height: 1.775rem;
    line-height: 1.775rem; }
  .btn-icon.btn-lg {
    width: 3.167rem;
    height: 3.167rem;
    line-height: 3.167rem; }
  .btn-icon.btn-social i:last-child {
    top: 100%; }
  .btn-icon.btn-social:hover i:first-child, .btn-icon.btn-social:focus i:first-child, .btn-icon.btn-social.active i:first-child {
    top: -100%; }
  .btn-icon.btn-social:hover i:last-child, .btn-icon.btn-social:focus i:last-child, .btn-icon.btn-social.active i:last-child {
    top: 0;
    color: #fff; }
  .btn-icon.btn-social-colored i:first-child {
    color: #fff; }

.btn.white,
.btn.btn-default {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); }
  .btn.white:not([disabled]):hover, .btn.white:not([disabled]):focus, .btn.white:not([disabled]).active,
  .btn.btn-default:not([disabled]):hover,
  .btn.btn-default:not([disabled]):focus,
  .btn.btn-default:not([disabled]).active {
    box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.1); }

.btn-default {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87); }

.btn-groups .btn {
  margin-bottom: .25rem; }

.btn-fw {
  min-width: 7rem; }

.card {
  border-color: rgba(120, 130, 140, 0.13); }

.carousel-control .glyphicon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  margin-left: -10px; }

.dropdown-menu {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left; }
  .dropdown-menu > li:not(.dropdown-item) > a {
    display: block;
    padding: 3px 20px; }
    .dropdown-menu > li:not(.dropdown-item) > a:hover, .dropdown-menu > li:not(.dropdown-item) > a:focus {
      background-color: rgba(0, 0, 0, 0.065); }
  .dropdown-menu > .divider {
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, 0.13); }
  .dropdown-menu .active {
    background-color: rgba(0, 0, 0, 0.065); }
  .dropdown-menu.pull-left {
    left: auto !important;
    right: 100%; }
  .dropdown-menu.pull-right {
    left: auto;
    right: 0;
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right; }
  .dropdown-menu.pull-up {
    top: 0; }
  .dropdown-menu.pull-down {
    top: 100% !important;
    left: 0 !important; }
  .dropup .dropdown-menu {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left; }
    .dropup .dropdown-menu.pull-right {
      -webkit-transform-origin: bottom right;
      -moz-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
      transform-origin: bottom right; }
  .dropdown-menu.datepicker, .dropdown-menu.timepicker {
    padding: 6px;
    font-size: 0.8rem; }
    .dropdown-menu.datepicker .btn, .dropdown-menu.timepicker .btn {
      font-size: 0.8rem; }
      .dropdown-menu.datepicker .btn.btn-primary, .dropdown-menu.timepicker .btn.btn-primary {
        background-color: #6887ff;
        color: #fff; }
    .dropdown-menu.datepicker .btn:not(:hover), .dropdown-menu.timepicker .btn:not(:hover) {
      box-shadow: 0 0 1px transparent; }

.dropdown-item,
.dropdown-item.active {
  color: inherit; }
  .dropdown-item:hover, .dropdown-item:focus,
  .dropdown-item.active:hover,
  .dropdown-item.active:focus {
    color: inherit;}

.dropdown-divider {
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, 0.13); }

.dropdown-menu-scale {
  display: block;
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none; }

.open > .dropdown-menu-scale {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
  display: block !important; }

.dropdown-header {
  padding: 8px 16px; }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu:hover > .dropdown-menu, .dropdown-submenu:focus > .dropdown-menu {
    display: block; }
  .dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px; }
  .dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-bottom: -6px; }

.popover {
  color: rgba(0, 0, 0, 0.87); }

@media (max-width: 543px) {
  .dropdown-menu.pull-none-xs {
    left: 0; } }

.form-control {
  border-color: rgba(120, 130, 140, 0.2);
  border-radius: 0;
  min-height: 2.375rem; }
  .form-control:focus {
    border-color: rgba(120, 130, 140, 0.3); }

.form-control-lg {
  min-height: 3.75rem; }

.form-control-sm {
  min-height: 2rem; }

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px; }

.input-group-addon {
  border-color: rgba(120, 130, 140, 0.2) !important;
  background-color: transparent;
  color: inherit; }

.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0; }
  .form-group-stack .form-control:focus, .form-group-stack .form-control:active {
    z-index: 1; }

.form-validation .form-control.ng-dirty.ng-invalid {
  border-color: #ef1960; }

.form-validation .form-control.ng-dirty.ng-valid, .form-validation .form-control.ng-dirty.ng-valid:focus {
  border-color: #6cc788; }

.form-validation .ui-checks .ng-invalid.ng-dirty + i {
  border-color: #ef1960; }

.form-file {
  position: relative; }
  .form-file input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }

.input-group-sm > .form-control {
  min-height: 1.95rem; }

option {
  color: #222; }

.active-checked {
  opacity: 0; }

input:checked + .active-checked {
  opacity: 1; }

.list-group {
  border-radius: 3px;
  background: #fff; }
  .list-group.no-radius .list-group-item {
    border-radius: 0 !important; }
  .list-group.no-borders .list-group-item {
    border: none; }
  .list-group.no-border .list-group-item {
    border-width: 1px 0; }
  .list-group.no-border .list-group-item {
    border-width: 1px 0; }
    .list-group.no-border .list-group-item:first-child {
      border-top-width: 0; }
    .list-group.no-border .list-group-item:last-child {
      border-bottom-width: 0; }
  .list-group.no-bg .list-group-item {
    background-color: transparent; }
  .list-group.list-group-gap {
    background: transparent; }

.list-group-item {
  border-color: rgba(120, 130, 140, 0.065);
  background: transparent;
  padding: 12px 16px;
  background-clip: padding-box; }
  .list-group-item.media {
    margin-top: 0; }
  .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.065);
    border-color: rgba(120, 130, 140, 0.065);
    border-bottom-color: rgba(120, 130, 140, 0.065); }
    .list-group-item.active a, .list-group-item.active:hover a, .list-group-item.active:focus a {
      color: inherit; }
  .list-group-item:first-child {
    border-top-color: rgba(120, 130, 140, 0.13); }
  .list-group-item:last-child {
    border-bottom-color: rgba(120, 130, 140, 0.13); }
  .list-group-alt .list-group-item:nth-child(2n+2) {
    background-color: rgba(0, 0, 0, 0.065); }
  .list-group-alt .list-group-item.active:nth-child(2n+2) {
    background-color: rgba(0, 0, 0, 0.065); }
  .list-group-lg .list-group-item {
    padding: 16px 24px; }
  .list-group-md .list-group-item {
    padding-top: 16px;
    padding-bottom: 16px; }
  .list-group-sm .list-group-item {
    padding: 10px 12px; }
  .list-group-gap .list-group-item {
    margin-bottom: 5px;
    border-radius: 3px; }
    .list-group-gap .list-group-item:first-child {
      border-top-color: rgba(120, 130, 140, 0.065); }
    .list-group-gap .list-group-item:last-child {
      border-bottom-color: rgba(120, 130, 140, 0.065); }

a.list-group-item {
  color: inherit; }
  a.list-group-item:hover, a.list-group-item:focus, a.list-group-item.hover {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.065); }

.modal .left {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal .right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal .top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal .bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.in .left,
.modal.in .right,
.modal.in .top,
.modal.in .bottom {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.modal.inactive {
  bottom: auto;
  overflow: visible; }

.modal-open-aside {
  overflow: visible; }

.modal > .left {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0; }

.modal > .right {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0; }

.modal > .top {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto; }

.modal > .bottom {
  position: fixed;
  z-index: 1055;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0; }

.modal-header,
.modal-content,
.modal-footer {
  border-color: rgba(120, 130, 140, 0.13); }

.modal-content {
  color: rgba(0, 0, 0, 0.87); }

.aside {
  color: rgba(0, 0, 0, 0.87); }

.aside-header,
.aside-header .close {
  color: inherit !important;
  background-color: transparent !important; }

.aside-header,
.aside-footer {
  border-color: rgba(120, 130, 140, 0.13) !important; }

.aside-title {
  font-size: 1.2rem;
  margin: .45rem 0; }

.modal-close-btn {
  position: absolute;
  top: 0;
  right: 0; }

.modal.animate .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal.animate .fade-right-big {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s; }

.modal.animate .fade-left-big {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s; }

.modal.animate .fade-up-big {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s; }

.modal.animate .fade-down-big {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s; }

.modal.animate .fade-right {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s; }

.modal.animate .fade-left {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s; }

.modal.animate .fade-up {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s; }

.modal.animate .fade-down {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s; }

.modal.animate .flip-x {
  -webkit-animation: flipOutX 0.5s;
  animation: flipOutX 0.5s; }

.modal.animate .flip-y {
  -webkit-animation: flipOutY 0.5s;
  animation: flipOutY 0.5s; }

.modal.animate .zoom {
  -webkit-animation: zoomOut 0.5s;
  animation: zoomOut 0.5s; }

.modal.animate .roll {
  -webkit-animation: rollOut 0.5s;
  animation: rollOut 0.5s; }

.modal.animate .bounce {
  -webkit-animation: bounceOut 0.5s;
  animation: bounceOut 0.5s; }

.modal.animate .rotate {
  -webkit-animation: rotateOut 0.5s;
  animation: rotateOut 0.5s; }

.modal.animate.in .fade-right-big {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s; }

.modal.animate.in .fade-left-big {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s; }

.modal.animate.in .fade-up-big {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s; }

.modal.animate.in .fade-down-big {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s; }

.modal.animate.in .fade-right {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s; }

.modal.animate.in .fade-left {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s; }

.modal.animate.in .fade-up {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s; }

.modal.animate.in .fade-down {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s; }

.modal.animate.in .flip-x {
  -webkit-animation: flipInX 0.5s;
  animation: flipInX 0.5s; }

.modal.animate.in .flip-y {
  -webkit-animation: flipInY 0.5s;
  animation: flipInY 0.5s; }

.modal.animate.in .zoom {
  -webkit-animation: zoomIn 0.5s;
  animation: zoomIn 0.5s; }

.modal.animate.in .roll {
  -webkit-animation: rollIn 0.5s;
  animation: rollIn 0.5s; }

.modal.animate.in .bounce {
  -webkit-animation: bounceIn 0.5s;
  animation: bounceIn 0.5s; }

.modal.animate.in .rotate {
  -webkit-animation: rotateIn 0.5s;
  animation: rotateIn 0.5s; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border: inherit; }

.nav-item {
  border: inherit; }

.nav-link {
  border: inherit;
  position: relative; }

.top-nav {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border: inherit; }

.top-nav-item {
  float: left;
  margin-left: 10px;
  height: 56px;
  display: flex;
  align-items: center;
  border: inherit; }

.top-nav-link {
  display: flex;
  align-items: center;
  height: 56px;
  border: inherit;
  background: none;
  position: relative; }

.nav-md .nav-link {
  padding: 0.5rem 1rem; }

.nav-md .nav-item + .nav-item {
  margin-left: 1.5rem; }

.nav-sm .nav-link {
  padding: 0.25rem 0.75rem; }

.nav-xs .nav-link {
  padding: 0.15rem 0.5rem;
  font-size: 90%; }

.nav-rounded .nav-link {
  border-radius: 2rem; }

.nav-tabs {
  border-bottom-width: 0;
  position: relative;
  z-index: 1; }
  .nav-tabs .nav-link {
    background: transparent !important;
    color: inherit !important; }
    .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: rgba(120, 130, 140, 0.13);
      border-bottom-color: transparent; }

.tab-content.tab-alt .tab-pane {
  display: block;
  height: 0;
  overflow: hidden; }
  .tab-content.tab-alt .tab-pane.active {
    height: auto;
    overflow: visible; }

.nav-justified .nav-item {
  display: table-cell;
  width: 1%;
  float: none !important;
  text-align: center; }

.nav-lists .nav-item {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  background-clip: padding-box; }

.nav-active-border .nav-link:before {
  content: '';
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent; }

.nav-active-border .nav-link:hover:before, .nav-active-border .nav-link:focus:before, .nav-active-border .nav-link.active:before {
  left: 0%;
  right: 0%;
  border-bottom-color: inherit; }

.nav-active-border.top .nav-link:before {
  bottom: auto;
  top: 0; }

.nav-active-border.left .nav-link {
  float: none; }
  .nav-active-border.left .nav-link:before {
    border-bottom-width: 0;
    right: auto;
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: transparent;
    left: 0;
    top: 50%;
    bottom: 50%; }
  .nav-active-border.left .nav-link:hover:before, .nav-active-border.left .nav-link:focus:before, .nav-active-border.left .nav-link.active:before {
    top: 0%;
    bottom: 0%;
    border-left-color: inherit; }

.nav-active-border.left.right .nav-link:before {
  left: auto;
  right: 0; }

.breadcrumb {
  background-color: rgba(0, 0, 0, 0.065); }
  .breadcrumb > li + li:before {
    content: '/ '; }

.navbar {
  border: none;
  padding: 0 1rem;
  flex-shrink: 0;
  min-height: 3.5rem; }
  .navbar .up {
    margin: 0 -0.6em; }

.navbar-item {
  margin-right: 1rem;
  margin-bottom: 0; }

.navbar-nav > .nav-link,
.navbar-nav > .nav-item > .nav-link,
.navbar-item,
.navbar-brand {
  padding: 0;
  line-height: 3.5rem;
  white-space: nowrap; }

.navbar-brand {
  padding: 0;
  font-size: 20px;
  font-weight: bold; }
  .navbar-brand:hover {
    text-decoration: none; }
  .navbar-brand img,
  .navbar-brand svg {
    position: relative;
    max-height: 24px;
    top: 16px;
    display: inline-block;
    vertical-align: top; }
  .navbar-brand > span {
    display: inline-block;
    line-height: 0.8;
    margin-left: 4px; }
  .navbar-brand.md img,
  .navbar-brand.md svg {
    max-height: 32px;
    top: 12px; }
  .navbar-brand.lg img,
  .navbar-brand.lg svg {
    max-height: 48px;
    top: 4px; }
  .pull-center .navbar-brand {
    margin-left: -50%;
    float: left; }

.navbar .collapsing, .navbar
.collapse.in {
  clear: left;
  padding-bottom: 1rem; }

.collapsing .navbar-nav,
.collapse.in .navbar-nav {
  float: none !important; }

.collapsing .navbar-nav:not(.navbar-nav-inline) .nav-item,
.collapse.in .navbar-nav:not(.navbar-nav-inline) .nav-item {
  float: none;
  margin: 0; }

.collapsing .navbar-nav:not(.navbar-nav-inline) .nav-link,
.collapse.in .navbar-nav:not(.navbar-nav-inline) .nav-link {
  display: block;
  line-height: 2 !important; }

.collapsing .navbar-form,
.collapse.in .navbar-form {
  margin: 0 !important;
  padding: 0.5rem 0;
  float: none !important; }

.collapsing .nav-active-border,
.collapse.in .nav-active-border {
  border-color: transparent; }

.collapsing .dropdown-menu-scale,
.collapse.in .dropdown-menu-scale {
  display: none; }

@media (min-width: 768px) {
  .navbar.container {
    padding-left: 0;
    padding-right: 0; } }

.pagination .page-item,
.pagination > li > a,
.pagination > li > span,
.pager .page-item,
.pager > li > a,
.pager > li > span {
  color: inherit !important;
  background-color: transparent !important;
  border-color: rgba(120, 130, 140, 0.13) !important; }
  .pagination .page-item:hover, .pagination .page-item:focus,
  .pagination > li > a:hover,
  .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus,
  .pager .page-item:hover,
  .pager .page-item:focus,
  .pager > li > a:hover,
  .pager > li > a:focus,
  .pager > li > span:hover,
  .pager > li > span:focus {
    border-color: rgba(120, 130, 140, 0.13);
    background-color: rgba(0, 0, 0, 0.065);
    color: inherit; }

.pagination .page-item.active,
.pagination > .active > a,
.pagination > .active > span,
.pager .page-item.active,
.pager > .active > a,
.pager > .active > span {
  color: white !important;
  background-color: #02b875 !important;
  border-color: #02b875 !important; }

.pagination > li {
  display: inline;
  list-style: none; }

.pagination > li > a {
  position: relative;
  float: left;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #0275d8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }
  .pagination > li > a:focus {
    color: #014c8c;
    background-color: #eceeef;
    border-color: #ddd; }
  .pagination > li > a:hover {
    color: #014c8c;
    background-color: #eceeef;
    border-color: #ddd; }

.progress[value]::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.065); }

.progress {
  border-radius: .25rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.065); }

.progress-bar {
  display: inline-block;
  float: left;
  height: 100%;
  border-radius: .25rem;
  text-align: center;
  background-color: #0074d9;
  font-size: 0.8em;
  -webkit-transition: width .6s ease;
  transition: width .6s ease; }

.progress-xxs {
  height: 4px; }

.progress-xs {
  height: 8px; }

.progress-sm {
  height: 12px;
  font-size: 0.8em; }

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px; }

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.panel .table {
  border-color: rgba(120, 130, 140, 0.045) !important; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
  padding-left: 16px;
  padding-right: 16px;
  border-color: rgba(120, 130, 140, 0.045); }

.table > thead > tr > th {
  padding: 10px 16px;
  border-color: rgba(120, 130, 140, 0.045); }

.table-condensed thead > tr > th,
.table-condensed tbody > tr > th,
.table-condensed tfoot > tr > th,
.table-condensed thead > tr > td,
.table-condensed tbody > tr > td,
.table-condensed tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border-color: rgba(120, 130, 140, 0.045); }

.table-striped > tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
  background-clip: padding-box; }

.table-striped > thead > th {
  background-color: rgba(0, 0, 0, 0.025);
  border-right: 1px solid rgba(120, 130, 140, 0.045); }
  .table-striped > thead > th:last-child {
    border-right: none; }

.table-hover tr:hover,
.table tr.active,
.table td.active,
.table th.active {
  background-color: rgba(0, 0, 0, 0.065) !important; }

.arrow {
  position: absolute;
  z-index: 10; }
  .arrow:before, .arrow:after {
    position: absolute;
    left: 0;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: 9px;
    border-color: transparent;
    border-style: solid; }
  .arrow:after {
    border-width: 8px; }
  .arrow.top {
    top: -9px;
    left: 50%;
    margin-left: -9px; }
    .arrow.top.pull-in {
      top: -4px; }
    .arrow.top:before {
      border-bottom-color: rgba(120, 130, 140, 0.13);
      border-top-width: 0; }
    .arrow.top:after {
      top: 1px;
      left: 1px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: inherit;
      border-left-color: transparent;
      border-top-width: 0; }
  .arrow.right {
    top: 50%;
    right: 0;
    margin-top: -9px; }
    .arrow.right.pull-in {
      right: 4px; }
    .arrow.right:before {
      border-left-color: rgba(120, 130, 140, 0.13);
      border-right-width: 0; }
    .arrow.right:after {
      top: 1px;
      left: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: inherit;
      border-right-width: 0; }
  .arrow.bottom {
    bottom: 0;
    left: 50%;
    margin-left: -9px; }
    .arrow.bottom.pull-in {
      bottom: 4px; }
    .arrow.bottom:before {
      border-top-color: rgba(120, 130, 140, 0.13);
      border-bottom-width: 0; }
    .arrow.bottom:after {
      top: 0px;
      left: 1px;
      border-top-color: inherit;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-bottom-width: 0; }
  .arrow.left {
    top: 50%;
    left: -9px;
    margin-top: -9px; }
    .arrow.left.pull-in {
      left: -4px; }
    .arrow.left:before {
      border-right-color: rgba(120, 130, 140, 0.13);
      border-left-width: 0; }
    .arrow.left:after {
      top: 1px;
      left: 1px;
      border-top-color: transparent;
      border-right-color: inherit;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-left-width: 0; }
  .arrow.pull-left {
    left: 18px; }
  .arrow.pull-right {
    left: auto;
    right: 26px; }
  .arrow.pull-top {
    top: 18px; }
  .arrow.pull-bottom {
    top: auto;
    bottom: 26px; }
  .arrow.b-primary:before, .arrow.b-info:before, .arrow.b-success:before, .arrow.b-warning:before, .arrow.b-danger:before, .arrow.b-accent:before, .arrow.b-dark:before {
    border-color: transparent; }

.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold; }
  .avatar img {
    border-radius: 500px;
    width: 100%; }
  .avatar i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 1px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%; }
    .avatar i.right {
      left: auto;
      right: 0; }
    .avatar i.bottom {
      left: auto;
      top: auto;
      bottom: 0;
      right: 0; }
    .avatar i.left {
      top: auto;
      bottom: 0; }
    .avatar i.on {
      background-color: #6cc788; }
    .avatar i.off {
      background-color: #f8f8f8; }
    .avatar i.away {
      background-color: #f77a99; }
    .avatar i.busy {
      background-color: #ef1960; }
  .avatar.w-32 i {
    margin: 0px; }
  .avatar.w-48 i {
    margin: 2px; }
  .avatar.w-56 i {
    margin: 3px; }
  .avatar.w-64 i {
    margin: 4px; }
  .avatar.w-96 i {
    margin: 9px; }
  .avatar.w-128 i {
    margin: 14px; }

.row-col {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%; }
  .row-col > [class*="col-"],
  .row-col > [class*=" col-"] {
    vertical-align: top;
    float: none;
    padding: 0;
    position: static; }

.row-row {
  display: table-row;
  height: 100%; }

.row-cell {
  display: table-cell;
  vertical-align: top; }

.row-body {
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto; }
  .ie .row-body {
    display: table-cell; }

.row-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.v-m {
  vertical-align: middle !important; }

.v-t {
  vertical-align: top !important; }

.v-b {
  vertical-align: bottom !important; }

.v-c {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center; }

@media (min-width: 992px) {
  .row-col > [class*="col-lg"],
  .row-col > [class*=" col-lg"] {
    display: table-cell;
    height: 100%; } }

@media (min-width: 768px) {
  .row-col > [class*="col-md"],
  .row-col > [class*=" col-md"] {
    display: table-cell;
    height: 100%; } }

@media (min-width: 544px) {
  .row-col > [class*="col-sm"],
  .row-col > [class*=" col-sm"] {
    display: table-cell;
    height: 100%; } }

.row-col > [class*="col-xs"],
.row-col > [class*=" col-xs"] {
  display: table-cell;
  height: 100%; }

@media (max-width: 543px) {
  .row-col-xs {
    display: block; }
    .row-col-xs .row-body {
      overflow: visible !important; }
    .row-col-xs .row-inner {
      position: static; } }

.box,
.box-color {
  background-color: #fff;
  position: relative;
  margin-bottom: 1.5rem; }

.box-header {
  position: relative;
  padding: 1rem; }
  .box-header h2,
  .box-header h3,
  .box-header h4 {
    margin: 0;
    font-size: 18px;
    line-height: 1; }
  .box-header h3 {
    font-size: 16px; }
  .box-header h4 {
    font-size: 15px; }
  .box-header small {
    display: block;
    margin-top: 4px;
    opacity: 0.6; }

.box-body {
  padding: 1rem; }

.box-footer {
  padding: 1rem; }

.box-divider {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin: 0 16px;
  height: 0; }

.box-tool {
  position: absolute;
  right: 16px;
  top: 14px; }

.box-shadow,
.box-shadow .box,
.box-shadow .box-color {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03); }

.box-shadow-z0,
.box-shadow-z0 .box,
.box-shadow-z0 .box-color {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15); }

.box-shadow-z1,
.box-shadow-z1 .box,
.box-shadow-z1 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 0px rgba(0, 0, 0, 0.02); }

.box-shadow-z2,
.box-shadow-z2 .box,
.box-shadow-z2 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02); }

.box-shadow-z3,
.box-shadow-z3 .box,
.box-shadow-z3 .box-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -1px 0px rgba(0, 0, 0, 0.02); }

.box-radius-1x,
.box-radius-1x .box,
.box-radius-1x .box-color {
  border-radius: 2px; }

.box-radius-2x,
.box-radius-2x .box,
.box-radius-2x .box-color {
  border-radius: 3px; }

.box-radius-3x,
.box-radius-3x .box,
.box-radius-3x .box-color {
  border-radius: 4px; }

.ui-check {
  position: relative;
  padding-left: 20px;
  cursor: pointer; }
  .ui-check input {
    opacity: 0;
    position: absolute;
    z-index: -1; }
    .ui-check input:checked + i:before {
      left: 5px;
      top: 5px;
      width: 6px;
      height: 6px;
      background-color: #02b875; }
    .ui-check input:checked + span .active {
      display: inherit; }
    .ui-check input[type="radio"] + i, .ui-check input[type="radio"] + i:before {
      border-radius: 50%; }
    .ui-check input[disabled] + i,
    fieldset[disabled] .ui-check input + i {
      border-color: rgba(134, 143, 152, 0.2); }
      .ui-check input[disabled] + i:before,
      fieldset[disabled] .ui-check input + i:before {
        background-color: rgba(134, 143, 152, 0.2); }
  .ui-check > i {
    width: 16px;
    height: 16px;
    line-height: 1;
    box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    background-clip: padding-box;
    position: relative; }
    .ui-check > i:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background-color: transparent; }
  .ui-check > span {
    margin-left: -20px; }
    .ui-check > span .active {
      display: none; }
  .ui-check.ui-check-color input:checked + i:before {
    background-color: #fff; }

.ui-check-md input:checked + i:before {
  left: 6px;
  top: 6px; }

.ui-check-md > i {
  width: 18px;
  height: 18px; }

.ui-check-lg input:checked + i:before {
  width: 12px;
  height: 12px;
  left: 9px;
  top: 9px; }

.ui-check-lg > i {
  width: 30px;
  height: 30px; }

[layout],
[data-layout] {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

[layout=column],
[data-layout=column] {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

[layout=row],
[data-layout=row] {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

[layout-wrap],
[data-layout-wrap] {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

[layout-fill],
[data-layout-fill] {
  margin: 0;
  min-height: 100%;
  width: 100%; }

@-moz-document url-prefix() {
  [layout-fill],
  [data-layout-fill] {
    margin: 0;
    width: 100%;
    min-height: auto;
    height: inherit; } }

[flex],
[data-flex] {
  box-sizing: border-box;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

[flex-no-shrink],
[data-flex-no-shrink] {
  flex-shrink: 0; }

/*layout*/
.app-aside {
  position: fixed !important;
  z-index: 1030;
  float: left;
  height: 100%; }

.app-content {
  box-shadow: none;
  padding: 0; }
  .app-content::after {
    content: "";
    display: table;
    clear: both; }

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: inherit;
  z-index: 1060; }
  .app-header ~ .app-body {
    padding-top: 3.5rem; }

.app-body-inner {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto; }

.app-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  margin: inherit; }
  .app-footer:not(.hide) ~ .app-body {
    padding-bottom: 3.125rem; }

.app-fixed {
  overflow: hidden; }
  .app-fixed .app {
    height: 100%; }
  .app-fixed .app-content {
    position: relative;
    min-height: 100%; }
  .app-fixed .app-footer {
    margin-left: 0; }

@media (min-width: 992px) {
  .app-aside {
    opacity: 1;
    display: block !important; }
    .app-aside,
    .app-aside .scroll {
      width: 12.5rem; }
    .app-aside.lg:not(.folded),
    .app-aside.lg:not(.folded) .scroll {
      width: 13.75rem; }
    .app-aside.lg:not(.hide) ~ .app-content {
      margin-left: 13.75rem; }
    .app-aside.sm:not(.folded),
    .app-aside.sm:not(.folded) .scroll {
      width: 11.25rem; }
    .app-aside.sm:not(.hide) ~ .app-content {
      margin-left: 11.25rem; }
    .app-aside.folded {
      width: 4rem; }
      .app-aside.folded:not(.hide) ~ .app-content {
        margin-left: 4rem; }
      .app-aside.folded.md {
        width: 5rem; }
        .app-aside.folded.md:not(.hide) ~ .app-content {
          margin-left: 5rem; }
    .app-aside:not(.hide) ~ .app-content {
      margin-left: 12.5rem; }
    .app-aside .left {
      position: absolute;
      right: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
  .app-header.hidden-lg-up ~ .app-body {
    padding-top: 0; }
  .hide-scroll {
    width: auto;
    margin-right: -17px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991px) {
  .app-aside.modal {
    position: fixed;
    z-index: 1050; }
  .app-aside .left {
    position: fixed;
    bottom: inherit;
    width: 304px; } }

@media print {
  .app-aside,
  .app-header,
  .app-footer,
  .switcher {
    display: none; } }

/* fix ie9 */
.ie9 .app-aside {
  position: static !important; }
  .ie9 .app-aside .left {
    width: inherit;
    position: absolute; }

@media (max-width: 991px) {
  body.container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 992px) {
  body.container {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
    body.container {
      padding-left: 0;
      padding-right: 0; }
    body.container .app-aside {
      left: inherit;
      right: auto; }
      body.container .app-aside.lg ~ .app-content .app-header {
        width: 45rem; }
      body.container .app-aside.sm ~ .app-content .app-header {
        width: 47.5rem; }
      body.container .app-aside.folded ~ .app-content .app-header {
        width: 54.75rem; }
      body.container .app-aside.folded.md ~ .app-content .app-header {
        width: 53.75rem; }
      body.container .app-aside ~ .app-content .app-header {
        width: 46.25rem; }
    body.container .app-header {
      left: inherit;
      right: auto;
      margin-left: 0;
      width: 58.75rem; } }

@media (min-width: 1200px) {
  body.container .app-aside.lg ~ .app-content .app-header {
    width: 57.5rem; }
  body.container .app-aside.sm ~ .app-content .app-header {
    width: 60rem; }
  body.container .app-aside.folded ~ .app-content .app-header {
    width: 67.25rem; }
  body.container .app-aside.folded.md ~ .app-content .app-header {
    width: 66.25rem; }
  body.container .app-aside ~ .app-content .app-header {
    width: 58.75rem; }
  body.container .app-header {
    width: 71.25rem; } }

.list {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px; }
  .list.no-border {
    padding-top: 8px;
    padding-bottom: 8px; }

.list-item {
  display: block;
  position: relative;
  padding: 12px 16px; }
  .list-item::after {
    content: "";
    display: table;
    clear: both; }
  .list-item:last-child .list-body:after {
    border: none; }
  .no-border .list-item {
    padding-top: 8px;
    padding-bottom: 8px; }
  .no-padding .list-item {
    padding-left: 0;
    padding-right: 0; }

.list-left {
  float: left;
  padding-right: 1rem; }
  .list-left + .list-body {
    margin-left: 56px; }

.list-body h3 {
  font-size: 16px;
  margin: 0 0 3px 0;
  font-weight: normal; }

.list-body:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.065); }

.inset .list-body:after {
  left: 72px; }

.no-border .list-body:after {
  display: none; }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.pace-inactive {
  display: none; }

.pace .pace-progress {
  background: #02b875;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px; }

.pace .pace-activity {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #02b875;
  border-left-color: #02b875;
  border-radius: 10px;
  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite; }

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }

.navside {
  min-height: calc(100vh - 56px);
  border: inherit; }
  .navside ul {
    padding-left: 0;
    padding-right: 0;
    list-style: none; }
  .navside [flex],
  .navside [data-flex] {
    overflow: auto; }
  .navside .nav {
    border: inherit; }
    .navside .nav li {
      padding: 5px;
      border: inherit;
      position: relative; }
      .navside .nav li li a {
        line-height: 2rem;
        padding-left: 3.5rem; }
        .navside .nav li li a .nav-text {
          padding: 0.4375rem 0; }
      .navside .nav li li li a {
        padding-left: 4.5rem; }
      .navside .nav li li li li a {
        padding-left: 5.5rem; }
      .navside .nav li a {
        display: block;
        padding: 0 1rem;
        line-height: 2.25rem;
        position: relative; }
        .navside .nav li a::after {
          content: "";
          display: table;
          clear: both; }
        .navside .nav li a:hover, .navside .nav li a:focus {
          background-color: rgba(255, 255, 255, 0.065); }
      .navside .nav li.active {
        background-color: rgba(255, 255, 255, 0.065); }
        .navside .nav li.active > a {
          background-color: transparent; }
          .navside .nav li.active > a .nav-caret i {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .navside .nav-header {
    padding: 0 1rem;
    line-height: 2.25rem; }
    .navside .nav-header:hover, .navside .nav-header:focus {
      background-color: transparent !important; }
    .navside .nav-header:after {
      display: none; }
  .navside .navbar-brand {
    float: none;
    margin-right: 0; }

.nav-fold {
  padding: 0.75rem 1rem; }

.nav-text {
  font-size: 16px;
  color: #ddd;
  display: block;
  line-height: 1.125rem;
  padding: 0.5625rem 0; }
  .nav > li > a .nav-text {
    font-weight: 500; }
  .nav-text span,
  .nav-text small {
    display: block; }
  .navside .nav-text {
    opacity: 0.75; }
  .navside a:hover > .nav-text,
  .navside a:focus > .nav-text,
  .navside .active > a > .nav-text {
    opacity: 1; }
  .navbar .nav-text {
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    padding: 0; }
    .navbar .nav-text span.text-xs {
      margin-top: 0.25rem; }

.nav-icon {
  height: 28px;
  display: flex;
  align-items: center;
  float: left;
  line-height: inherit;
  margin-right: 10px;
  top: 0;
  min-width: 1.5rem;
  text-align: center; }
  .nav-icon i {
    position: relative; }
    .nav-icon i svg,
    .nav-icon i img {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .navbar .nav-icon {
    margin-right: 0.5rem; }

.nav-label {
  float: right;
  margin-left: 1rem;
  font-style: normal;
  z-index: 1; }
  .navbar .nav-label {
    margin-left: 0.5rem; }

.nav-caret {
  float: right;
  margin-left: 1rem;
  opacity: 0.45; }
  .nav-caret i {
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out; }
  .navbar .nav-caret {
    margin-left: 0.5rem; }

.nav-sub {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease 0s;
  transition: max-height 0.4s ease 0s; }
  .active > .nav-sub {
    max-height: 50rem; }

@media (min-width: 992px) {
  .folded,
  .folded .scroll {
    width: 4rem; }
  .folded.md,
  .folded.md .scroll {
    width: 5rem; }
  .folded .hidden-folded {
    display: none; }
  .folded .nav li ul {
    display: none; }
  .folded .nav > li > a {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px; }
    .folded .nav > li > a .nav-caret,
    .folded .nav > li > a .nav-text {
      display: none; }
    .folded .nav > li > a .nav-label {
      position: absolute;
      top: -6px;
      right: 6px; }
    .folded .nav > li > a .nav-icon {
      float: none;
      line-height: 2.5rem;
      margin-left: 0;
      margin-right: 0;
      position: relative; }
  .folded.show-text .nav > li > a .nav-text {
    display: block;
    margin-top: -18px;
    font-size: 0.85em; }
  .folded .navbar {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px; }
  .folded .navbar-brand {
    float: none;
    margin: 0; }
  .folded .nav-stacked .nav {
    margin: 0 12px; }
  .folded .nav-fold {
    padding: 8px 16px; }
    .folded .nav-fold .pull-left {
      float: none !important;
      margin: 0; }
      .folded .nav-fold .pull-left img {
        width: 100%;
        height: auto; }
  .folded.nav-expand {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute; }
    .folded.nav-expand:hover,
    .folded.nav-expand:hover .scroll, .folded.nav-expand:focus,
    .folded.nav-expand:focus .scroll, .folded.nav-expand.active,
    .folded.nav-expand.active .scroll {
      width: 12.5rem !important; }
    .folded.nav-expand:hover > *, .folded.nav-expand:focus > *, .folded.nav-expand.active > * {
      width: 12.5rem; }
    .folded.nav-expand:hover .hidden-folded, .folded.nav-expand:focus .hidden-folded, .folded.nav-expand.active .hidden-folded {
      display: block; }
    .folded.nav-expand:hover .hidden-folded.inline, .folded.nav-expand:focus .hidden-folded.inline, .folded.nav-expand.active .hidden-folded.inline {
      display: inline-block; }
    .folded.nav-expand:hover .nav li ul, .folded.nav-expand:focus .nav li ul, .folded.nav-expand.active .nav li ul {
      display: inherit; }
    .folded.nav-expand:hover .nav > li > a, .folded.nav-expand:focus .nav > li > a, .folded.nav-expand.active .nav > li > a {
      text-align: left;
      padding-left: 16px;
      padding-right: 16px; }
      .folded.nav-expand:hover .nav > li > a .nav-caret,
      .folded.nav-expand:hover .nav > li > a .nav-text, .folded.nav-expand:focus .nav > li > a .nav-caret,
      .folded.nav-expand:focus .nav > li > a .nav-text, .folded.nav-expand.active .nav > li > a .nav-caret,
      .folded.nav-expand.active .nav > li > a .nav-text {
        display: block; }
      .folded.nav-expand:hover .nav > li > a .nav-text, .folded.nav-expand:focus .nav > li > a .nav-text, .folded.nav-expand.active .nav > li > a .nav-text {
        font-size: 1em;
        margin: 0; }
      .folded.nav-expand:hover .nav > li > a .nav-label, .folded.nav-expand:focus .nav > li > a .nav-label, .folded.nav-expand.active .nav > li > a .nav-label {
        position: static; }
      .folded.nav-expand:hover .nav > li > a .nav-icon, .folded.nav-expand:focus .nav > li > a .nav-icon, .folded.nav-expand.active .nav > li > a .nav-icon {
        float: left;
        line-height: inherit;
        margin-right: 16px; }
    .folded.nav-expand:hover .navbar, .folded.nav-expand:focus .navbar, .folded.nav-expand.active .navbar {
      text-align: left;
      padding-left: 1rem;
      padding-right: 1rem; }
    .folded.nav-expand:hover .nav-stacked .nav, .folded.nav-expand:focus .nav-stacked .nav, .folded.nav-expand.active .nav-stacked .nav {
      margin: 0 8px; }
      .folded.nav-expand:hover .nav-stacked .nav > li > a, .folded.nav-expand:focus .nav-stacked .nav > li > a, .folded.nav-expand.active .nav-stacked .nav > li > a {
        padding: 0 8px; }
    .folded.nav-expand:hover .nav-fold, .folded.nav-expand:focus .nav-fold, .folded.nav-expand.active .nav-fold {
      padding: 12px 16px; }
      .folded.nav-expand:hover .nav-fold .pull-left, .folded.nav-expand:focus .nav-fold .pull-left, .folded.nav-expand.active .nav-fold .pull-left {
        float: left !important; }
        .folded.nav-expand:hover .nav-fold .pull-left img, .folded.nav-expand:focus .nav-fold .pull-left img, .folded.nav-expand.active .nav-fold .pull-left img {
          height: auto; }
          .folded.nav-expand:hover .nav-fold .pull-left img.w-40, .folded.nav-expand:focus .nav-fold .pull-left img.w-40, .folded.nav-expand.active .nav-fold .pull-left img.w-40 {
            width: 40px; }
          .folded.nav-expand:hover .nav-fold .pull-left img.w-48, .folded.nav-expand:focus .nav-fold .pull-left img.w-48, .folded.nav-expand.active .nav-fold .pull-left img.w-48 {
            width: 48px; }
  .nav-dropup.modal,
  .nav-dropup [flex],
  .nav-dropup .row-body,
  .folded.nav-dropdown.modal,
  .folded.nav-dropdown [flex],
  .folded.nav-dropdown .row-body {
    overflow: visible !important; }
  .nav-dropup .nav > li:hover > ul, .nav-dropup .nav > li:focus > ul,
  .folded.nav-dropdown .nav > li:hover > ul,
  .folded.nav-dropdown .nav > li:focus > ul {
    display: block; }
  .nav-dropup .nav > li > ul,
  .folded.nav-dropdown .nav > li > ul {
    display: none;
    overflow: visible;
    max-height: 999px;
    color: rgba(255, 255, 255, 0.87);
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 100%;
    top: 0;
    padding: 6px 0; }
    .nav-dropup .nav > li > ul a,
    .folded.nav-dropdown .nav > li > ul a {
      padding-left: 16px !important;
      padding-right: 16px !important;
      min-width: 160px; }
    .nav-dropup .nav > li > ul li.active ul,
    .folded.nav-dropdown .nav > li > ul li.active ul {
      display: block; }
    .nav-dropup .nav > li > ul .nav-text,
    .folded.nav-dropdown .nav > li > ul .nav-text {
      padding: 6px 0 !important; }
  .nav-dropup .nav > li .nav-mega,
  .folded.nav-dropdown .nav > li .nav-mega {
    width: 320px; }
    .nav-dropup .nav > li .nav-mega > li,
    .folded.nav-dropdown .nav > li .nav-mega > li {
      width: 160px;
      float: left; }
    .nav-dropup .nav > li .nav-mega.nav-mega-3,
    .folded.nav-dropdown .nav > li .nav-mega.nav-mega-3 {
      width: 480px; } }

.nav-border .nav > li.active:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  border-left-color: inherit;
  border-left-width: 3px;
  border-left-style: solid; }

.nav-stacked .nav {
  margin: 0 8px; }
  .nav-stacked .nav > li > a {
    line-height: 1.75rem;
    padding: 0 0.5rem;
    margin: 2px 0; }
  .nav-stacked .nav > li > a .nav-text {
      padding: 0.3125rem 0; }
  .nav-stacked .nav > li li a {
    padding-left: 3rem; }
  .nav-stacked .nav > li li li a {
    padding-left: 4rem; }
  .nav-stacked .nav > li li li li a {
    padding-left: 5rem; }
  .nav-stacked .nav > li.active > a {
    color: rgba(255, 255, 255, 0.87);
    background-color: #02b875; }
  .nav-stacked .nav li a {
    border-radius: 3px; }
  .nav-stacked .nav .nav-header {
    padding-left: 8px;
    padding-right: 8px; }

.nav-light .nav .nav-icon i svg,
.nav-light .nav .nav-icon i img {
  display: block; }

.nav-center {
  text-align: center; }
  .nav-center .nav-icon {
    display: none; }
  .nav-center .nav-caret {
    position: absolute;
    right: 10px; }
  .nav-center .nav-label {
    position: absolute;
    padding-left: 10px; }
  .nav-center .nav a {
    padding-left: 16px !important; }
  .nav-center .nav-fold .pull-left {
    float: none !important;
    margin-bottom: 10px;
    display: inline-block; }

.nav-active-bg .nav-link.active,
.nav-active-bg .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f2f2f2 !important; }

.nav-active-dark .nav-link.active,
.nav-active-dark .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #1c202b !important; }

.nav-active-black .nav-link.active,
.nav-active-black .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #17171b !important; }

.nav-active-primary .nav-link.active,
.nav-active-primary .nav > li.active > a, 
.nav-active-primary .nav > li > a.active {
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #02b875 !important; }

.nav-active-accent .nav-link.active,
.nav-active-accent .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #a88add !important; }

.nav-active-warn .nav-link.active,
.nav-active-warn .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #fcc100 !important; }

.nav-active-success .nav-link.active,
.nav-active-success .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6cc788 !important; }

.nav-active-info .nav-link.active,
.nav-active-info .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6887ff !important; }

.nav-active-warning .nav-link.active,
.nav-active-warning .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #f77a99 !important; }

.nav-active-danger .nav-link.active,
.nav-active-danger .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #ef1960 !important; }

.nav-active-light .nav-link.active,
.nav-active-light .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f8f8f8 !important; }

.nav-active-white .nav-link.active,
.nav-active-white .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffffff !important; }

.nav-active-red .nav-link.active,
.nav-active-red .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #f44336 !important; }

.nav-active-pink .nav-link.active,
.nav-active-pink .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #e91e63 !important; }

.nav-active-purple .nav-link.active,
.nav-active-purple .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #9c27b0 !important; }

.nav-active-deep-purple .nav-link.active,
.nav-active-deep-purple .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #673ab7 !important; }

.nav-active-indigo .nav-link.active,
.nav-active-indigo .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #3f51b5 !important; }

.nav-active-blue .nav-link.active,
.nav-active-blue .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #2196f3 !important; }

.nav-active-light-blue .nav-link.active,
.nav-active-light-blue .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #03a9f4 !important; }

.nav-active-cyan .nav-link.active,
.nav-active-cyan .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #00bcd4 !important; }

.nav-active-teal .nav-link.active,
.nav-active-teal .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #009688 !important; }

.nav-active-green .nav-link.active,
.nav-active-green .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #4caf50 !important; }

.nav-active-light-green .nav-link.active,
.nav-active-light-green .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #8bc34a !important; }

.nav-active-lime .nav-link.active,
.nav-active-lime .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #cddc39 !important; }

.nav-active-yellow .nav-link.active,
.nav-active-yellow .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffeb3b !important; }

.nav-active-amber .nav-link.active,
.nav-active-amber .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #ffc107 !important; }

.nav-active-orange .nav-link.active,
.nav-active-orange .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #ff9800 !important; }

.nav-active-deep-orange .nav-link.active,
.nav-active-deep-orange .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #ff5722 !important; }

.nav-active-brown .nav-link.active,
.nav-active-brown .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #795548 !important; }

.nav-active-blue-grey .nav-link.active,
.nav-active-blue-grey .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #607d8b !important; }

.nav-active-grey .nav-link.active,
.nav-active-grey .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #363c43 !important; }

.ui-icon {
  display: inline-block;
  text-align: center;
  height: 1em;
  background: transparent !important; }
  .ui-icon:before {
    position: relative;
    z-index: 1; }
  .ui-icon:after {
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    border-radius: 2em;
    width: 2em;
    height: 2em;
    top: -1.5em;
    display: block;
    position: relative;
    z-index: 0;
    background-clip: padding-box; }
  .ui-icon.b-2x:after {
    border-width: 2px; }
  .ui-icon.b-3x:after {
    border-width: 3px; }
  .ui-icon.ui-icon-sm:after {
    width: 1.7em;
    height: 1.7em;
    top: -1.35em; }
  .ui-icon.primary:after {
    background-color: #02b875; }
  .ui-icon.success:after {
    background-color: #6cc788; }
  .ui-icon.info:after {
    background-color: #6887ff; }
  .ui-icon.warning:after {
    background-color: #f77a99; }
  .ui-icon.danger:after {
    background-color: #ef1960; }
  .ui-icon.accent:after {
    background-color: #a88add; }
  .ui-icon.dark:after {
    background-color: #1c202b; }
  .ui-icon.light:after {
    background-color: #f8f8f8; }
  .ui-icon.white:after {
    background-color: #fff; }

.item {
  position: relative;
  margin: 0 auto; }
  .item::after {
    content: "";
    display: table;
    clear: both; }
  .item .top {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
    background-repeat: repeat-x;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .item .bottom {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    background-repeat: repeat-x;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .item .center {
    position: absolute;
    padding: 0 !important;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px; }
  .no-gutter .item {
    border-radius: 0; }

.item-overlay {
  line-height: 1;
  color: #fff;
  padding: 8px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  border-radius: inherit; }
  .item-overlay.active, .item-overlay.open,
  .item-overlay .item:focus,
  .item:hover .item-overlay {
    opacity: 1;
    display: block; }
  .item-overlay.w-full {
    top: 0;
    bottom: 0; }
  .item-overlay > a,
  .item-overlay > .btn {
    min-width: 20px;
    text-align: center;
    display: inline-block; }

.item-media {
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: inherit; }
  .item-media:after {
    content: '';
    display: block;
    padding-top: 100%; }

.item-media-21by9:after {
  padding-top: 42.857143%; }

.item-media-16by9:after {
  padding-top: 56.25%; }

.item-media-4by3:after {
  padding-top: 75%; }

.item-media-2by3:after {
  padding-top: 150%; }

.item-media-3by4:after {
  padding-top: 133.33333%; }

.item-media-2by1:after {
  padding-top: 50%; }

.item-media-2by1 .item-media-content {
  max-width: 50%; }

.item-media-2by1 .item-overlay {
  width: 50%; }
  .item-media-2by1 .item-overlay.top ~ .item-media-content {
    left: 50%; }
  .item-media-2by1 .item-overlay.bottom {
    left: auto;
    right: 0; }

.item-media-1by2:after {
  padding-top: 200%; }

.item-media-1by2 .item-media-content {
  max-height: 50%; }

.item-media-1by2 .item-overlay {
  height: 50%; }
  .item-media-1by2 .item-overlay.top ~ .item-media-content {
    top: 50%; }

.item-media iframe,
.item-media-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  border-radius: inherit;
  background-size: cover;
  background-repeat: none;
  background-position: 50% 50%;
  background-color: rgba(120, 120, 120, 0.2); }

.item-info {
  position: relative;
  padding: 10px 0 20px 0;
  border-radius: inherit; }
  .item-info .bottom {
    bottom: 100%; }

.item-info-overlay .item-info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 15px 20px;
  color: #fff; }
  .item-info-overlay .item-info .item-overlay {
    bottom: 0; }
  .item-info-overlay .item-info .item-title {
    font-size: 1.125rem; }
  .item-info-overlay .item-info .item-title,
  .item-info-overlay .item-info .item-author {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 2px rgba(0, 0, 0, 0.1); }

.item-info-overlay .item-overlay {
  z-index: 1; }

.item-title {
  font-size: 14px;
  font-weight: 600; }

.item-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.item-bg img {
  position: absolute;
  width: 110%;
  left: 50%;
  top: 50%;
  margin-left: -55%;
  margin-top: -55%; }

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px); }

.blur-5 {
  opacity: 0.5;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.opacity {
  opacity: 0.5; }

.opacity-3 {
  opacity: 0.3; }

.visible-list {
  display: none !important; }

.item-grid .item-info {
  min-height: 50px; }

.item-list .item {
  padding: 12px 10px;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 5; }
  .item-list .item:hover, .item-list .item:focus {
    background-color: rgba(120, 120, 120, 0.1); }
    .item-list .item:hover .item-info:after, .item-list .item:focus .item-info:after {
      display: none; }

.item-list .visible-list {
  display: block !important; }

.item-list.item-list-lg .item-media {
  width: 90px;
  height: 90px; }

.item-list.item-list-lg .item-info {
  min-height: 90px; }

.item-list.item-list-md .item-media {
  width: 60px;
  height: 60px; }

.item-list.item-list-md .item-info {
  min-height: 60px; }

.item-list.item-list-sm .item-media {
  width: 40px;
  height: 40px; }

.item-list.item-list-sm .item-info {
  min-height: 40px; }

.item-list.item-list-xs .item-media {
  width: 0;
  height: 0;
  position: static; }
  .item-list.item-list-xs .item-media button {
    width: 30px;
    height: 30px; }

.item-list.item-list-xs .item-media-content {
  display: none; }

.item-list.item-list-xs .item-info {
  min-height: 20px; }

.item-list.item-list-xs .center {
  left: 30px;
  margin-top: -15px; }

.item-list.item-list-xs .item:hover:before,
.item-list.item-list-xs .item:focus:before,
.item-list.item-list-xs .item.active:before,
.item-list.item-list-xs .open .item:before {
  opacity: 0; }

.item-list.item-list-by > div {
  float: none;
  width: 100%; }

.item-list .item-media {
  width: 120px;
  height: 120px;
  padding-bottom: 0;
  float: left;
  margin-right: 16px;
  z-index: 1; }

.item-list .item-info {
  padding: 0;
  position: relative;
  min-height: 120px; }
  .item-list .item-info .bottom {
    position: relative;
    float: right;
    background: transparent;
    color: inherit; }
  .item-list .item-info:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12px;
    border-bottom: 1px solid rgba(120, 120, 120, 0.1); }

.item-list .item-title {
  line-height: 1.4; }

.item-list .item-meta-right {
  position: absolute;
  right: 0;
  top: 2px; }

.item-list .item:hover .item-meta-right,
.item-list .item:focus .item-meta-right,
.item-list .open ~ .item-meta .item-meta-right {
  display: none; }

.item-list-li {
  counter-reset: li; }
  .item-list-li .item {
    padding-left: 50px; }
  .item-list-li .item-overlay {
    padding: 3px 8px; }
  .item-list-li .item:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    content: counter(li);
    counter-increment: li;
    text-align: center;
    width: 50px;
    display: inline-block;
    opacity: 0.6;
    font-size: 16px;
    font-weight: bold; }

.item-masonry .item {
  margin-bottom: 24px; }

.item-masonry .item-media-1by2 {
  padding-top: 24px; }

.item-masonry.row-sm .item-media-1by2 {
  padding-top: 16px; }

.item-masonry.row-xs .item {
  margin-bottom: 8px; }

.item-masonry.row-xs .item-media-1by2 {
  padding-top: 8px; }

@media (max-width: 991px) {
  .item-masonry .item {
    margin-bottom: 16px; }
  .item-masonry .item-media-1by2 {
    padding-top: 16px; }
  .item-list .item {
    margin-left: -8px;
    margin-right: -8px; } }

@media (max-width: 767px) {

  .item-list-li .item:before {
    width: 20px; }
  .item-list-li .item {
    padding-left: 30px; }

  .item-masonry .item {
    margin-bottom: 8px; }
  .item-masonry .item-media-1by2 {
    padding-top: 8px; }
  .item-masonry.row-sm .item {
    margin-bottom: 8px; }
  .item-masonry.row-sm .item-media-1by2 {
    padding-top: 8px; }
  .item-masonry.row-xs .item {
    margin-bottom: 4px; }
  .item-masonry.row-xs .item-media-1by2 {
    padding-top: 4px; }
  .item-list .item {
    margin-left: -4px;
    margin-right: -4px; } }

.page-content {
  min-height: calc(100vh - 56px);
  position: relative;
  z-index: 10; }

.page-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 0; }
  .page-bg:before {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-image: inherit;
    background-position: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.1; }
  .page-bg:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    background-repeat: repeat-x; }
  .light .page-bg:after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f8f8f8); }
  .grey .page-bg:before {
    opacity: 0.15; }
  .grey .page-bg:after {
    background-image: linear-gradient(to bottom, transparent, #363c43); }
  .dark .page-bg:before {
    opacity: 0.15; }
  .dark .page-bg:after {
    background-image: linear-gradient(to bottom, transparent, #1c202b); }
  .black .page-bg:before {
    opacity: 0.15; }
  .black .page-bg:after {
    background-image: linear-gradient(to bottom, transparent, #17171b); }

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: inherit;
  background-position: 50% 50%;
  position: relative; }
  .cover:after {
    position: absolute;
    z-index: 0;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    background-color: inherit;
    opacity: 0.65; }
  .cover.cover-gd:after {
    background-image: linear-gradient(to right, #6887ff, #02b875); }
  .cover > * {
    position: relative;
    z-index: 1; }

.switcher {
  z-index: 1000;
  position: fixed;
  top: 60px;
  right: -240px;
  width: 240px;
  -webkit-transition: right 0.2s ease;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box; }
  .switcher.active {
    right: -2px;
    z-index: 1060; }

.switcher .sw-btn {
  position: absolute;
  left: -43px;
  top: -1px;
  padding: 10px 15px;
  z-index: 1045;
  border: 1px solid rgba(120, 120, 120, 0.1);
  border-right-width: 0;
  background-clip: padding-box;
  border-radius: 24px 0 0 24px; }

#sw-demo {
  top: 118px; }
  #sw-demo.active {
    top: 78px; }

.list-icon i {
  margin-right: 16px; }

.list-icon div {
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*Charts*/
.jqstooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.easyPieChart {
  position: relative;
  text-align: center; }
  .easyPieChart > div {
    position: absolute;
    width: 100%;
    line-height: 1;
    top: 40%; }
    .easyPieChart > div img {
      margin-top: -4px; }
  .easyPieChart canvas {
    vertical-align: middle; }

#flotTip,
.flotTip {
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  z-index: 100;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.legendColorBox > div {
  border: none !important;
  margin: 5px; }
  .legendColorBox > div > div {
    border-radius: 10px; }

.st-sort-ascent:before {
  content: '\25B2'; }

.st-sort-descent:before {
  content: '\25BC'; }

.st-selected td {
  background: rgba(0, 0, 0, 0.065); }

.dark .btn-default.form-control,
.black .btn-default.form-control,
.grey .btn-default.form-control {
  background: transparent !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important; }

.ui-select-bootstrap > .ui-select-choices {
  overflow-y: scroll; }

.box .dataTables_wrapper {
  padding-top: 10px; }

.box .dataTables_wrapper > .row {
  margin: 0; }

.box .dataTables_wrapper > .row > .col-sm-12 {
  padding: 0; }

.dataTables_wrapper.form-inline .form-control {
  width: auto; }

.footable-odd {
  background-color: rgba(0, 0, 0, 0.025) !important; }

.note-editor {
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit; }

.note-toolbar .note-color .dropdown-toggle {
  padding-left: 2px !important; }

.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar {
  background-color: transparent !important;
  color: inherit !important; }

.note-toolbar {
  background-color: rgba(120, 130, 140, 0.1); }

.ie .ie-show {
  display: block; }

.nav a.waves-effect {
  -webkit-mask-image: -webkit-radial-gradient(circle, #ffffff 100%, #000000 100%); }

.sortable-placeholder {
  list-style: none;
  border: 1px dashed #fff;
  min-height: 40px; }

.jscroll-loading {
  padding: 5px 10px; }

.jscroll-loading-center .jscroll-loading {
  text-align: center; }

.streamline {
  position: relative;
  border-color: rgba(120, 130, 140, 0.13); }
  .streamline:after,
  .streamline .sl-item:before,
  .streamline .sl-icon:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    margin-left: -4px;
    top: 6px;
    left: 0; }
  .streamline:after {
    top: auto;
    bottom: 0; }

.sl-icon {
  position: absolute;
  left: -10px;
  z-index: 1;
  border: inherit; }
  .sl-icon > i {
    width: 20px;
    height: 20px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    color: #fff; }
  .streamline .sl-icon:before {
    width: 20px;
    height: 20px;
    border-width: 10px;
    margin: 0 !important;
    top: 0 !important; }

.sl-item {
  border-color: rgba(120, 130, 140, 0.13);
  position: relative;
  padding-bottom: 1px; }
  .sl-item::after {
    content: "";
    display: table;
    clear: both; }
  .sl-item:after {
    top: 2px;
    bottom: auto; }
  .sl-item.b-l {
    margin-left: -1px; }

.sl-left {
  float: left;
  position: relative;
  z-index: 1;
  margin-left: -20px;
  margin-bottom: 24px; }
  .sl-left img {
    max-width: 40px; }
  .sl-left + .sl-content {
    margin-left: 36px; }

.sl-content {
  margin-left: 24px;
  padding-bottom: 16px; }

.sl-author {
  margin-bottom: 10px; }

.sl-date {
  font-size: 0.85em; }

.sl-footer {
  margin-bottom: 10px; }

.ui-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 30px;
  background-color: #6cc788;
  margin: 0; }
  .ui-switch input {
    position: absolute;
    opacity: 0; }
    .ui-switch input:checked + i:before {
      top: 50%;
      bottom: 50%;
      left: 50%;
      right: 5px;
      border-width: 0;
      border-radius: 5px; }
    .ui-switch input:checked + i:after {
      margin-left: 15px; }
  .ui-switch i:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 30px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  .ui-switch i:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 16px;
    top: 1px;
    bottom: 1px;
    border-radius: 50%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    -webkit-transition: margin 0.3s;
    transition: margin 0.3s; }

.ui-switch-md {
  width: 40px;
  height: 24px; }
  .ui-switch-md input:checked + i:after {
    margin-left: 17px; }
  .ui-switch-md i:after {
    width: 22px; }

.ui-switch-lg {
  width: 50px;
  height: 30px; }
  .ui-switch-lg input:checked + i:after {
    margin-left: 21px; }
  .ui-switch-lg i:after {
    width: 28px; }

.timeline {
  margin: 0;
  padding: 0; }

.tl-item {
  display: block; }
  .tl-item::after {
    content: "";
    display: table;
    clear: both; }

.visible-left {
  display: none; }

.tl-wrap {
  display: block;
  margin-left: 6em;
  padding: 15px 0 15px 20px;
  border-style: solid;
  border-color: rgba(120, 130, 140, 0.13);
  border-width: 0 0 0 2px; }
  .tl-wrap::after {
    content: "";
    display: table;
    clear: both; }
  .tl-wrap:before {
    position: relative;
    content: "";
    float: left;
    top: 15px;
    margin-left: -26px;
    width: 10px;
    height: 10px;
    border-color: inherit;
    border-width: 3px;
    border-radius: 50%;
    border-style: solid;
    background: #f8f8f8;
    box-shadow: 0 0 0 4px #f2f2f2; }

.tl-date {
  position: relative;
  top: 10px;
  float: left;
  margin-left: -7.5em;
  display: block;
  width: 4.5em;
  text-align: right; }

.tl-content {
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px; }
  .tl-content.block {
    display: block;
    width: 100%; }
  .tl-content.panel {
    margin-bottom: 0; }

.tl-header {
  display: block;
  width: 12em;
  text-align: center;
  margin-left: 1px; }

.timeline-center .tl-item {
  margin-left: 50%; }
  .timeline-center .tl-item .tl-wrap {
    margin-left: -2px; }

.timeline-center .tl-header {
  width: auto;
  margin-left: -1px; }

.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%; }
  .timeline-center .tl-left .hidden-left {
    display: none !important; }
  .timeline-center .tl-left .visible-left {
    display: inherit; }
  .timeline-center .tl-left .tl-wrap {
    float: right;
    margin-right: 0px;
    border-left-width: 0;
    border-right-width: 2px;
    padding-left: 0;
    padding-right: 20px; }
    .timeline-center .tl-left .tl-wrap:before {
      float: right;
      margin-left: 0;
      margin-right: -26px; }
  .timeline-center .tl-left .tl-date {
    float: right;
    margin-left: 0;
    margin-right: -8.5em;
    text-align: left; }

.dark .white,
.dark .box {
  background-color: #232836; }

.dark.app {
  background-color: #1c202b; }

.black .white,
.black .box {
  background-color: #1f1f25; }

.black.app {
  background-color: #17171b; }

.grey .white,
.grey .box {
  background-color: #3e454d; }

.grey.app {
  background-color: #363c43; }

.dark .form-control,
.black .form-control,
.grey .form-control {
  background: transparent;
  background-color: rgba(255, 255, 255, 0.1);
  color: inherit; }
  .dark .form-control:focus,
  .black .form-control:focus,
  .grey .form-control:focus {
    border-color: rgba(255, 255, 255, 0.125); }

.dark .input-group-btn .btn,
.black .input-group-btn .btn,
.grey .input-group-btn .btn {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important; }

.dark .list-group,
.black .list-group,
.grey .list-group {
  background-color: transparent; }

.lter {
  background-color: rgba(255, 255, 255, 0.035); }

.dker {
  background-color: rgba(0, 0, 0, 0.035); }

.light {
  background-color: #f8f8f8; }

.white {
  background-color: #fff; }

.dark-white {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff; }

.black {
  color: rgba(255, 255, 255, 0.87);
  background-color: #17171b; }
  .black .lt {
    background-color: #1d1d22; }
  .black .dk {
    background-color: #111114; }
  .black .bg {
    background-color: #17171b; }

.dark {
  color: rgba(255, 255, 255, 0.87);
  background-color: #1c202b; }
  .dark .lt {
    background-color: #212633; }
  .dark .dk {
    background-color: #171a23; }
  .dark .bg {
    background-color: #1c202b; }

.grey {
  color: rgba(255, 255, 255, 0.87);
  background-color: #363c43; }
  .grey .lt {
    background-color: #3c424a; }
  .grey .dk {
    background-color: #30363c; }
  .grey .bg {
    background-color: #363c43; }

.primary {
  color: rgba(255, 255, 255, 0.87);
  background-color: #02b875; }
  .primary .lt {
    background-color: #02c57d; }
  .primary .dk {
    background-color: #02ab6d; }
  .primary .bg {
    background-color: #02b875; }

.accent {
  color: rgba(255, 255, 255, 0.87);
  background-color: #a88add; }
  .accent .lt {
    background-color: #af94e0; }
  .accent .dk {
    background-color: #a180da; }
  .accent .bg {
    background-color: #a88add; }

.warn {
  color: rgba(255, 255, 255, 0.87);
  background-color: #fcc100; }
  .warn .lt {
    background-color: #ffc60a; }
  .warn .dk {
    background-color: #efb700; }
  .warn .bg {
    background-color: #fcc100; }

.success {
  color: rgba(255, 255, 255, 0.87);
  background-color: #6cc788; }
  .success .lt {
    background-color: #75cb8f; }
  .success .dk {
    background-color: #63c381; }
  .success .bg {
    background-color: #6cc788; }

.info {
  color: rgba(255, 255, 255, 0.87);
  background-color: #6887ff; }
  .info .lt {
    background-color: #7591ff; }
  .info .dk {
    background-color: #5b7dff; }
  .info .bg {
    background-color: #6887ff; }

.warning {
  color: rgba(255, 255, 255, 0.87);
  background-color: #f77a99; }
  .warning .lt {
    background-color: #f886a2; }
  .warning .dk {
    background-color: #f66e90; }
  .warning .bg {
    background-color: #f77a99; }

.danger {
  color: rgba(255, 255, 255, 0.87);
  background-color: #ef1960; }
  .danger .lt {
    background-color: #f02568; }
  .danger .dk {
    background-color: #eb1059; }
  .danger .bg {
    background-color: #ef1960; }

.blue {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2196f3; }
  .blue .lt {
    background-color: #2d9cf4; }
  .blue .dk {
    background-color: #1590f2; }
  .blue .bg {
    background-color: #2196f3; }

.white-overlay {
  background-color: rgba(255, 255, 255, 0.85); }

.black-overlay {
  background-color: rgba(0, 0, 0, 0.85); }

.gd-overlay {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0); }

.bg-auto:before {
  content: "";
  position: absolute;
  width: inherit;
  top: 0;
  bottom: 0;
  background-color: inherit;
  border: inherit; }

.bg-auto.b-l:before {
  margin-left: -1px; }

.bg-auto.b-r:before {
  margin-right: -1px; }

.no-bg {
  background: transparent !important; }

@media (max-width: 767px) {
  .no-bg-xs {
    background-color: transparent; } }

.pos-rlt {
  position: relative;
  z-index: 1; }

.pos-abt {
  position: absolute; }

.pos-fix {
  position: fixed !important; }

.pos-stc {
  position: static !important; }

.block {
  display: block; }

.block.hide {
  display: none; }

.pull-none {
  float: none; }

.inline {
  display: inline-block; }

.inline-16 {
  width: 16px;
  height: 16px; }

.inline-24 {
  width: 24px;
  height: 24px; }

.none {
  display: none; }

.active > .inline,
.active > .auto .inline {
  display: none; }

.active > .none,
.active > .auto .none {
  display: inline-block; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.pointer {
  cursor: pointer; }

.rounded,
.circle {
  border-radius: 500px; }

.clear {
  display: block;
  overflow: hidden; }

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

@media (max-width: 767px) {
  .pos-stc-sm {
    position: static !important; } }

@media (max-width: 543px) {
  .pos-stc-xs {
    position: static !important; } }

.no-border {
  border-color: transparent;
  border-width: 0; }

.no-borders {
  border-width: 0 !important; }

.b {
  border-color: rgba(120, 130, 140, 0.13);
  background-clip: padding-box; }

.b-a {
  border: 1px solid rgba(120, 130, 140, 0.13); }

.b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13); }

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13); }

.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

.b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13); }

.b-2x {
  border-width: 2px; }

.b-3x {
  border-width: 3px; }

.b-4x {
  border-width: 4px; }

.b-5x {
  border-width: 5px; }

.b-t-2x {
  border-top-width: 2px !important; }

.b-t-3x {
  border-top-width: 3px !important; }

.b-t-4x {
  border-top-width: 4px !important; }

.b-t-5x {
  border-top-width: 5px !important; }

.b-r-2x {
  border-right-width: 2px !important; }

.b-r-3x {
  border-right-width: 3px !important; }

.b-r-4x {
  border-right-width: 4px !important; }

.b-r-5x {
  border-right-width: 5px !important; }

.b-b-2x {
  border-bottom-width: 2px !important; }

.b-b-3x {
  border-bottom-width: 3px !important; }

.b-b-4x {
  border-bottom-width: 4px !important; }

.b-b-5x {
  border-bottom-width: 5px !important; }

.b-l-2x {
  border-left-width: 2px !important; }

.b-l-3x {
  border-left-width: 3px !important; }

.b-l-4x {
  border-left-width: 4px !important; }

.b-l-5x {
  border-left-width: 5px !important; }

.b-light {
  border-color: #f8f8f8; }

.b-white {
  border-color: #fff; }

.b-primary {
  border-color: #02b875; }

.b-t-primary {
  border-top-color: #02b875; }

.b-r-primary {
  border-right-color: #02b875; }

.b-b-primary {
  border-bottom-color: #02b875; }

.b-l-primary {
  border-left-color: #02b875; }

.b-accent {
  border-color: #a88add; }

.b-t-accent {
  border-top-color: #a88add; }

.b-r-accent {
  border-right-color: #a88add; }

.b-b-accent {
  border-bottom-color: #a88add; }

.b-l-accent {
  border-left-color: #a88add; }

.b-warn {
  border-color: #fcc100; }

.b-t-warn {
  border-top-color: #fcc100; }

.b-r-warn {
  border-right-color: #fcc100; }

.b-b-warn {
  border-bottom-color: #fcc100; }

.b-l-warn {
  border-left-color: #fcc100; }

.b-success {
  border-color: #6cc788; }

.b-t-success {
  border-top-color: #6cc788; }

.b-r-success {
  border-right-color: #6cc788; }

.b-b-success {
  border-bottom-color: #6cc788; }

.b-l-success {
  border-left-color: #6cc788; }

.b-info {
  border-color: #6887ff; }

.b-t-info {
  border-top-color: #6887ff; }

.b-r-info {
  border-right-color: #6887ff; }

.b-b-info {
  border-bottom-color: #6887ff; }

.b-l-info {
  border-left-color: #6887ff; }

.b-warning {
  border-color: #f77a99; }

.b-t-warning {
  border-top-color: #f77a99; }

.b-r-warning {
  border-right-color: #f77a99; }

.b-b-warning {
  border-bottom-color: #f77a99; }

.b-l-warning {
  border-left-color: #f77a99; }

.b-danger {
  border-color: #ef1960; }

.b-t-danger {
  border-top-color: #ef1960; }

.b-r-danger {
  border-right-color: #ef1960; }

.b-b-danger {
  border-bottom-color: #ef1960; }

.b-l-danger {
  border-left-color: #ef1960; }

.b-light {
  border-color: #f8f8f8; }

.b-t-light {
  border-top-color: #f8f8f8; }

.b-r-light {
  border-right-color: #f8f8f8; }

.b-b-light {
  border-bottom-color: #f8f8f8; }

.b-l-light {
  border-left-color: #f8f8f8; }

.b-grey {
  border-color: #363c43; }

.b-t-grey {
  border-top-color: #363c43; }

.b-r-grey {
  border-right-color: #363c43; }

.b-b-grey {
  border-bottom-color: #363c43; }

.b-l-grey {
  border-left-color: #363c43; }

.b-dark {
  border-color: #1c202b; }

.b-t-dark {
  border-top-color: #1c202b; }

.b-r-dark {
  border-right-color: #1c202b; }

.b-b-dark {
  border-bottom-color: #1c202b; }

.b-l-dark {
  border-left-color: #1c202b; }

.b-black {
  border-color: #17171b; }

.b-t-black {
  border-top-color: #17171b; }

.b-r-black {
  border-right-color: #17171b; }

.b-b-black {
  border-bottom-color: #17171b; }

.b-l-black {
  border-left-color: #17171b; }

.b-white {
  border-color: #ffffff; }

.b-t-white {
  border-top-color: #ffffff; }

.b-r-white {
  border-right-color: #ffffff; }

.b-b-white {
  border-bottom-color: #ffffff; }

.b-l-white {
  border-left-color: #ffffff; }

.b-blue {
  border-color: #2196f3; }

.b-t-blue {
  border-top-color: #2196f3; }

.b-r-blue {
  border-right-color: #2196f3; }

.b-b-blue {
  border-bottom-color: #2196f3; }

.b-l-blue {
  border-left-color: #2196f3; }

.no-b-t {
  border-top-width: 0; }

.no-b-r {
  border-right-width: 0; }

.no-b-b {
  border-bottom-width: 0; }

.no-b-l {
  border-left-width: 0; }

.b-dashed {
  border-style: dashed !important; }

@media (max-width: 991px) {
  .no-border-md {
    border-width: 0; } }

@media (max-width: 767px) {
  .no-border-sm {
    border-width: 0; } }

@media (max-width: 543px) {
  .no-border-xs {
    border-width: 0; } }

.hover-action {
  display: none; }

.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s; }

.hover-anchor:hover .hover-action,
.hover-anchor:focus .hover-action,
.hover-anchor:active .hover-action {
  display: inherit; }

.hover-anchor:hover .hover-rotate,
.hover-anchor:focus .hover-rotate,
.hover-anchor:active .hover-rotate {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.hover-top:hover,
.hover-top:focus {
  position: relative;
  z-index: 1000; }

.m-a {
  margin: 1rem; }

.m-t {
  margin-top: 1rem; }

.m-r {
  margin-right: 1rem; }

.m-b {
  margin-bottom: 1rem; }

.m-l {
  margin-left: 1rem; }

.m-x {
  margin-right: 1rem;
  margin-left: 1rem; }

.m-y {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m-a-lg {
  margin: 3rem; }

.m-t-lg {
  margin-top: 3rem; }

.m-r-lg {
  margin-right: 3rem; }

.m-b-lg {
  margin-bottom: 3rem; }

.m-l-lg {
  margin-left: 3rem; }

.m-x-lg {
  margin-right: 3rem;
  margin-left: 3rem; }

.m-y-lg {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.m-a-md {
  margin: 1.5rem; }

.m-t-md {
  margin-top: 1.5rem; }

.m-r-md {
  margin-right: 1.5rem; }

.m-b-md {
  margin-bottom: 1.5rem; }

.m-l-md {
  margin-left: 1.5rem; }

.m-x-md {
  margin-right: 1.5rem;
  margin-left: 1.5rem; }

.m-y-md {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.m-a-sm {
  margin: 0.5rem; }

.m-t-sm {
  margin-top: 0.5rem; }

.m-r-sm {
  margin-right: 0.5rem; }

.m-b-sm {
  margin-bottom: 0.5rem; }

.m-l-sm {
  margin-left: 0.5rem; }

.m-x-sm {
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

.m-y-sm {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.m-a-xs {
  margin: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.m-x-xs {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }

.m-y-xs {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.p-a {
  padding: 1rem; }

.p-t {
  padding-top: 1rem; }

.p-r {
  padding-right: 1rem; }

.p-b {
  padding-bottom: 1rem; }

.p-l {
  padding-left: 1rem; }

.p-x {
  padding-right: 1rem;
  padding-left: 1rem; }

.p-y {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.p-a-lg {
  padding: 3rem; }

.p-t-lg {
  padding-top: 3rem; }

.p-r-lg {
  padding-right: 3rem; }

.p-b-lg {
  padding-bottom: 3rem; }

.p-l-lg {
  padding-left: 3rem; }

.p-x-lg {
  padding-right: 3rem;
  padding-left: 3rem; }

.p-y-lg {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.p-a-md {
  padding: 1.5rem; }

.p-t-md {
  padding-top: 1.5rem; }

.p-r-md {
  padding-right: 1.5rem; }

.p-b-md {
  padding-bottom: 1.5rem; }

.p-l-md {
  padding-left: 1.5rem; }

.p-x-md {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.p-y-md {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.p-a-sm {
  padding: 0.5rem; }

.p-t-sm {
  padding-top: 0.5rem; }

.p-r-sm {
  padding-right: 0.5rem; }

.p-b-sm {
  padding-bottom: 0.5rem; }

.p-l-sm {
  padding-left: 0.5rem; }

.p-x-sm {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.p-y-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.p-a-xs {
  padding: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.p-x-xs {
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

.p-y-xs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.padding {
  padding: 1.5rem 1.5rem; }

.margin {
  margin-bottom: 1.5rem; }

.padding-out {
  margin: -1.5rem -1.5rem; }

@media (max-width: 991px) {
  .padding {
    padding: 1.5rem 1rem; }
  .padding-out {
    margin: -1.5rem -1rem; }
  .margin {
    margin-bottom: 1rem; }
  .box,
  .box-color {
    margin-bottom: 1rem; }
  .no-padding-md {
    padding: 0 !important; }
  .no-margin-md {
    margin: 0 !important; } }

@media (max-width: 767px) {
  .padding {
    padding: 1.5rem 0.5rem; }
  .padding-out {
    margin: -1.5rem -0.5rem; }
  .margin {
    margin-bottom: 0.5rem; }
  .box,
  .box-color {
    margin-bottom: 0.5rem; }
  .no-padding-sm {
    padding: 0 !important; }
  .no-margin-sm {
    margin: 0 !important; } }

@media (max-width: 543px) {
  .no-padding-xs {
    padding: 0 !important; }
  .no-margin-xs {
    margin: 0 !important; } }

.no-radius {
  border-radius: 0 !important; }

.no-r-t {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.no-r-r {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.no-r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.no-r-l {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.r {
  border-radius: 3px; }

.r-t {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }

.r-r {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.r-b {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.r-l {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.r-2x {
  border-radius: 6px; }

.r-3x {
  border-radius: 9px; }

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.hover {
    overflow-y: hidden; }
    .scrollable.hover > * {
      margin-top: -1px; }
    .scrollable.hover:hover, .scrollable.hover:focus, .scrollable.hover:active {
      overflow: visible;
      overflow-y: auto; }
  .smart .scrollable {
    overflow-y: auto !important; }

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.scroll-y {
  overflow-y: auto; }

.scroll-x {
  overflow-x: auto; }

a.primary:hover {
  background-color: #02ab6d; }

a.text-primary:hover {
  color: #02ab6d; }

.text-primary,
.text-primary-hover a:hover,
.text-primary-hover .active > a {
  color: #02b875 !important; }

a.warn:hover {
  background-color: #efb700; }

a.text-warn:hover {
  color: #efb700; }

.text-warn,
.text-warn-hover a:hover,
.text-warn-hover .active > a {
  color: #fcc100 !important; }

a.accent:hover {
  background-color: #a180da; }

a.text-accent:hover {
  color: #a180da; }

.text-accent,
.text-accent-hover a:hover,
.text-accent-hover .active > a {
  color: #a88add !important; }

a.success:hover {
  background-color: #63c381; }

a.text-success:hover {
  color: #63c381; }

.text-success,
.text-success-hover a:hover,
.text-success-hover .active > a {
  color: #6cc788 !important; }

a.info:hover {
  background-color: #5b7dff; }

a.text-info:hover {
  color: #5b7dff; }

.text-info,
.text-info-hover a:hover,
.text-info-hover .active > a {
  color: #6887ff !important; }

a.warning:hover {
  background-color: #f66e90; }

a.text-warning:hover {
  color: #f66e90; }

.text-warning,
.text-warning-hover a:hover,
.text-warning-hover .active > a {
  color: #f77a99 !important; }

a.danger:hover {
  background-color: #eb1059; }

a.text-danger:hover {
  color: #eb1059; }

.text-danger,
.text-danger-hover a:hover,
.text-danger-hover .active > a {
  color: #ef1960 !important; }

a.dark:hover {
  background-color: #171a23; }

a.text-dark:hover {
  color: #171a23; }

.text-dark,
.text-dark-hover a:hover,
.text-dark-hover .active > a {
  color: #1c202b !important; }

a.blue:hover {
  background-color: #1590f2; }

a.text-blue:hover {
  color: #1590f2; }

.text-blue,
.text-blue-hover a:hover,
.text-blue-hover .active > a {
  color: #2196f3 !important; }

.text-white {
  color: white !important; }

.text-white-lt {
  color: rgba(255, 255, 255, 0.54); }

.text-white-dk {
  color: rgba(255, 255, 255, 0.87); }

.text-black {
  color: black; }

.text-black-lt {
  color: rgba(0, 0, 0, 0.54); }

.text-black-dk {
  color: rgba(0, 0, 0, 0.87); }

.text-muted {
  color: inherit !important;
  opacity: 0.6; }

a.text-muted:hover, a.text-muted:focus {
  opacity: 1; }

.text-color {
  color: rgba(0, 0, 0, 0.87); }

._100 {
  font-weight: 100; }

._200 {
  font-weight: 200; }

._300 {
  font-weight: 300; }

._400 {
  font-weight: 400; }

._500 {
  font-weight: 500; }

._600 {
  font-weight: 600; }

._700 {
  font-weight: 700; }

._800 {
  font-weight: 800; }

.text {
  font-size: 1rem; }

.text-xxs {
  font-size: 0.65rem; }

.text-xs {
  font-size: 0.65rem; }

.text-sm {
  font-size: 0.8rem; }

.text-md {
  font-size: 1.125rem; }

.text-lg {
  font-size: 1.5rem; }

.text-2x {
  font-size: 2em; }

.text-3x {
  font-size: 3em; }

.text-4x {
  font-size: 4em; }

.l-h-0 {
  line-height: 0; }

.l-h {
  line-height: 1.5; }

.l-h-1x {
  line-height: 1; }

.l-h-2x {
  line-height: 2em; }

.l-s-1x {
  letter-spacing: 1px; }

.l-s-2x {
  letter-spacing: 2px; }

.l-s-3x {
  letter-spacing: 3px; }

.l-s-4x {
  letter-spacing: 4px; }

.l-s-n-1x {
  letter-spacing: -1px; }

.l-s-n-2x {
  letter-spacing: -2px; }

.l-s-n-3x {
  letter-spacing: -3px; }

.l-s-n-4x {
  letter-spacing: -4px; }

.h-1x {
  height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.h-2x {
  height: 2.625rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.h-3x {
  height: 3.9375rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.h-4x {
  height: 5.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }

.text-u-c {
  text-transform: uppercase; }

.text-l-t {
  text-decoration: line-through; }

.text-u-l {
  text-decoration: underline; }

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); }

@media (max-width: 991px) {
  .display-1 {
    font-size: 5rem; }
  .display-2 {
    font-size: 4.5rem; }
  .display-3 {
    font-size: 3.5rem; }
  .display-4 {
    font-size: 2.5rem; } }

@media (max-width: 767px) {
  .display-1 {
    font-size: 4.5rem; }
  .display-2 {
    font-size: 4rem; }
  .display-3 {
    font-size: 3rem; }
  .display-4 {
    font-size: 2rem; } }

.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block; }

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block; }

.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block; }

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center; }

.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center; }

.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center; }

.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center; }

.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center; }

.w-64 {
  width: 64px; }

.w-96 {
  width: 96px; }

.w-128 {
  width: 128px; }

.w-xxs {
  width: 60px; }

.w-xs {
  width: 90px; }

.w-sm {
  width: 120px; }

.w {
  width: 180px; }

.w-md {
  width: 200px; }

.w-lg {
  width: 240px; }

.w-xl {
  width: 320px; }

.w-xxl {
  width: 344px; }

.w-full {
  width: 100%; }

.w-auto {
  width: auto; }

.h-auto {
  height: auto; }

.h-full {
  height: 100%; }

.h-v {
  height: 100vh; }

.h-v-5 {
  height: 50vh; }

@media (max-width: 1199px) {
  .w-auto-lg {
    width: auto; }
  .w-full-lg {
    width: 100%; } }

@media (max-width: 991px) {
  .w-auto-md {
    width: auto; }
  .w-full-md {
    width: 100%; } }

@media (max-width: 767px) {
  .w-auto-sm {
    width: auto; }
  .w-full-sm {
    width: 100%; } }

@media (max-width: 543px) {
  .w-auto-xs {
    width: auto; }
  .w-full-xs {
    width: 100%; } }

.md-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: inherit;
  font-variant: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-flat {
  background: transparent; }

.md-btn.md-flat:not([disabled]):hover,
.md-btn.md-flat:not([disabled]):focus {
  background-color: rgba(158, 158, 158, 0.2); }

.md-btn-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 2px; }

.md-btn[disabled],
.md-btn.md-raised[disabled],
.md-btn.md-fab[disabled] {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
  cursor: not-allowed; }

.md-fab {
  line-height: 44px; }

.md-fab-offset {
  margin-top: -20px;
  margin-bottom: -20px; }

.md-btn:focus {
  outline: none; }

.md-btn:hover {
  text-decoration: none; }

.md-btn.md-cornered {
  border-radius: 0; }

.md-btn.md-icon {
  padding: 0;
  background: none; }

.md-btn.md-raised {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.md-btn.md-fab {
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.2s linear;
  transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow; }

.md-btn.md-raised:not([disabled]),
.md-btn.md-fab {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-btn.md-raised:not([disabled]):focus,
.md-btn.md-raised:not([disabled]):hover,
.md-btn.md-fab:not([disabled]):focus,
.md-btn.md-fab:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0); }

.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute; }

.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute; }

.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute; }

.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute; }

.md-btn.md-fab.md-mini {
  width: 40px;
  height: 40px;
  line-height: 28px; }

.red {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.87); }

.red-50 {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.87); }

.red-100 {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87); }

.red-200 {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.87); }

.red-300 {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87); }

.red-400 {
  background-color: #ef5350;
  color: rgba(0, 0, 0, 0.87); }

.red-500 {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.87); }

.red-600 {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.87); }

.red-700 {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.87); }

.red-800 {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.87); }

.red-900 {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.87); }

.red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.87); }

.red-A200 {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.87); }

.red-A400 {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.87); }

.red-A700 {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.87); }

.pink {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.87); }

.pink-50 {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.87); }

.pink-100 {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.87); }

.pink-200 {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87); }

.pink-300 {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87); }

.pink-400 {
  background-color: #ec407a;
  color: rgba(0, 0, 0, 0.87); }

.pink-500 {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.87); }

.pink-600 {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.87); }

.pink-700 {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.87); }

.pink-800 {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.87); }

.pink-900 {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.87); }

.pink-A100 {
  background-color: #ff80ab;
  color: rgba(0, 0, 0, 0.87); }

.pink-A200 {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.87); }

.pink-A400 {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.87); }

.pink-A700 {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.87); }

.purple {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.87); }

.purple-50 {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.87); }

.purple-100 {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.87); }

.purple-200 {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87); }

.purple-300 {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.87); }

.purple-400 {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.87); }

.purple-500 {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.87); }

.purple-600 {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.87); }

.purple-700 {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.87); }

.purple-800 {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.87); }

.purple-900 {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.87); }

.purple-A100 {
  background-color: #ea80fc;
  color: rgba(0, 0, 0, 0.87); }

.purple-A200 {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.87); }

.purple-A400 {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.87); }

.purple-A700 {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-300 {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-400 {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500 {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600 {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700 {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800 {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900 {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A200 {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400 {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700 {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.87); }

.indigo {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.87); }

.indigo-50 {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100 {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200 {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87); }

.indigo-300 {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.87); }

.indigo-400 {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500 {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600 {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700 {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800 {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900 {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A100 {
  background-color: #8c9eff;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A200 {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A400 {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700 {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.87); }

.blue {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.87); }

.blue-50 {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.87); }

.blue-100 {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87); }

.blue-200 {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.87); }

.blue-300 {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.87); }

.blue-400 {
  background-color: #42a5f5;
  color: rgba(0, 0, 0, 0.87); }

.blue-500 {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.87); }

.blue-600 {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.87); }

.blue-700 {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.87); }

.blue-800 {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.87); }

.blue-900 {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.87); }

.blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.87); }

.blue-A200 {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.87); }

.blue-A400 {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.87); }

.blue-A700 {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.87); }

.light-blue {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-50 {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100 {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200 {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300 {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400 {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500 {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-600 {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-700 {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-800 {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-900 {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.87); }

.light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A700 {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.87); }

.cyan {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.87); }

.cyan-50 {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100 {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200 {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300 {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400 {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500 {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.87); }

.cyan-600 {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.87); }

.cyan-700 {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.87); }

.cyan-800 {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.87); }

.cyan-900 {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.87); }

.cyan-A100 {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200 {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400 {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700 {
  background-color: #00b8d4;
  color: rgba(0, 0, 0, 0.87); }

.teal {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.87); }

.teal-50 {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.87); }

.teal-100 {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.87); }

.teal-200 {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87); }

.teal-300 {
  background-color: #4db6ac;
  color: rgba(0, 0, 0, 0.87); }

.teal-400 {
  background-color: #26a69a;
  color: rgba(0, 0, 0, 0.87); }

.teal-500 {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.87); }

.teal-600 {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.87); }

.teal-700 {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.87); }

.teal-800 {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.87); }

.teal-900 {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.87); }

.teal-A100 {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200 {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400 {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700 {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87); }

.green {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.87); }

.green-50 {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.87); }

.green-100 {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87); }

.green-200 {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.87); }

.green-300 {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.87); }

.green-400 {
  background-color: #66bb6a;
  color: rgba(0, 0, 0, 0.87); }

.green-500 {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.87); }

.green-600 {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.87); }

.green-700 {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.87); }

.green-800 {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.87); }

.green-900 {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.87); }

.green-A100 {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.87); }

.green-A200 {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.87); }

.green-A400 {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.87); }

.green-A700 {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.87); }

.light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50 {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200 {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300 {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400 {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500 {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600 {
  background-color: #7cb342;
  color: rgba(0, 0, 0, 0.87); }

.light-green-700 {
  background-color: #689f38;
  color: rgba(0, 0, 0, 0.87); }

.light-green-800 {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.87); }

.light-green-900 {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.87); }

.light-green-A100 {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200 {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400 {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700 {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.87); }

.lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87); }

.lime-50 {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.87); }

.lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.87); }

.lime-200 {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.87); }

.lime-300 {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.87); }

.lime-400 {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.87); }

.lime-500 {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87); }

.lime-600 {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.87); }

.lime-700 {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.87); }

.lime-800 {
  background-color: #9e9d24;
  color: rgba(0, 0, 0, 0.87); }

.lime-900 {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.87); }

.lime-A100 {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200 {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400 {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700 {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.87); }

.yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50 {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100 {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200 {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300 {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500 {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600 {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700 {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800 {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900 {
  background-color: #f57f17;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100 {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200 {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400 {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700 {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.87); }

.amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87); }

.amber-50 {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.87); }

.amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.87); }

.amber-200 {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87); }

.amber-300 {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.87); }

.amber-400 {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87); }

.amber-500 {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87); }

.amber-600 {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.87); }

.amber-700 {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.87); }

.amber-800 {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.87); }

.amber-900 {
  background-color: #ff6f00;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100 {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200 {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400 {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700 {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.87); }

.orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87); }

.orange-50 {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.87); }

.orange-100 {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87); }

.orange-200 {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.87); }

.orange-300 {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.87); }

.orange-400 {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.87); }

.orange-500 {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87); }

.orange-600 {
  background-color: #fb8c00;
  color: rgba(0, 0, 0, 0.87); }

.orange-700 {
  background-color: #f57c00;
  color: rgba(0, 0, 0, 0.87); }

.orange-800 {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.87); }

.orange-900 {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.87); }

.orange-A100 {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200 {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400 {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700 {
  background-color: #ff6d00;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-50 {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100 {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200 {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300 {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400 {
  background-color: #ff7043;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-500 {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-600 {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-700 {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-800 {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-900 {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A400 {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.87); }

.deep-orange-A700 {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.87); }

.brown {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.87); }

.brown-50 {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.87); }

.brown-100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.87); }

.brown-200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87); }

.brown-300 {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.87); }

.brown-400 {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.87); }

.brown-500 {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.87); }

.brown-600 {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.87); }

.brown-700 {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.87); }

.brown-800 {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.87); }

.brown-900 {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-50 {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300 {
  background-color: #90a4ae;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-400 {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-500 {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600 {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700 {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800 {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900 {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.87); }

.grey-50 {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.grey-100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87); }

.grey-200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87); }

.grey-300 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }

.grey-400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87); }

.grey-500 {
  background-color: #9e9e9e;
  color: rgba(0, 0, 0, 0.87); }

.grey-600 {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.87); }

.grey-700 {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.87); }

.grey-800 {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.87); }

.grey-900 {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.87); }

.md-form-group {
  padding: 18px 0 24px 0;
  position: relative; }

.md-input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit; }
  .md-input:focus, .md-input.focus {
    border-color: #02b875;
    border-bottom-width: 2px;
    padding-bottom: 1px; }
    .md-input:focus ~ label, .md-input.focus ~ label {
      color: #02b875;
      opacity: 1;
      top: 0 !important;
      font-size: 0.85em !important; }
  .float-label .md-input ~ label {
    top: 20px;
    font-size: 1em; }
  .float-label .md-input.ng-dirty ~ label, .float-label .md-input.has-value ~ label {
    top: 0;
    font-size: 0.85em; }
  .md-input ~ label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-size: 0.85em;
    position: absolute;
    z-index: 0;
    opacity: 0.5;
    display: inline-block;
    top: 0px;
    left: 0; }
  .md-input.disabled, .md-input[disabled] {
    opacity: 0.5; }

textarea.md-input {
  height: auto; }

.md-input-white:focus, .md-input-white.focus {
  border-color: #fff; }
  .md-input-white:focus ~ label, .md-input-white.focus ~ label {
    color: #fff; }

.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: 0.85em; }

.md-input-msg.right {
  right: 0; }

.md-check {
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0;
  display: inline-block;
  position: relative; }
  .md-check input {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    margin-left: -1.25rem; }
    .md-check input:checked + i:before {
      border-width: 0;
      background-color: inherit; }
    .md-check input:checked + span .active {
      display: inherit; }
    .md-check input[type="radio"] + i, .md-check input[type="radio"] + i:before {
      border-radius: 50%; }
    .md-check input[type="checkbox"]:checked + i:after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: 6px;
      top: 2px;
      display: table;
      width: 6px;
      height: 12px;
      border: 2px solid;
      border-top: 0;
      border-left: 0;
      content: ' '; }
    .md-check input[type="radio"]:checked + i:after {
      position: absolute;
      left: 6px;
      top: 6px;
      display: table;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
      content: ' '; }
    .md-check input[disabled] + i:before,
    fieldset[disabled] .md-check input + i:before {
      opacity: 0.5; }
    .md-check input[disabled]:checked + i:before {
      opacity: 0.5; }
  .md-check > i {
    width: 0;
    height: 18px;
    line-height: 1;
    margin-left: -20px;
    margin-top: 1px;
    float: left;
    display: inline-block;
    vertical-align: middle;
    margin-right: 28px;
    position: relative; }
    .md-check > i:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 100%;
      border: 2px solid #9e9e9e;
      border-radius: 2px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    .md-check > i.no-icon:after {
      display: none !important; }

.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px; }
  .md-switch input {
    position: absolute;
    cursor: pointer;
    width: 36px;
    height: 20px;
    z-index: 1;
    opacity: 0;
    margin-left: -36px; }
    .md-switch input:checked + i:before {
      background: inherit;
      opacity: 0.5; }
    .md-switch input:checked + i:after {
      background: inherit;
      left: 16px; }
    .md-switch input[disabled] + i:before,
    fieldset[disabled] .md-switch input + i:before {
      background-color: rgba(0, 0, 0, 0.12); }
    .md-switch input[disabled] + i:after,
    fieldset[disabled] .md-switch input + i:after {
      background-color: #bdbdbd; }
  .md-switch i {
    width: 0px;
    height: 18px;
    line-height: 1;
    margin-left: -36px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 44px;
    position: relative; }
    .md-switch i:before {
      content: "";
      left: 1px;
      width: 34px;
      top: 3px;
      height: 14px;
      border-radius: 8px;
      position: absolute;
      background-color: #9e9e9e;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    .md-switch i:after {
      content: "";
      position: absolute;
      margin: 0;
      left: 0;
      top: 0;
      outline: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
