

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Regular.eot');
  src: url('../assets/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SFProText-Regular.woff2') format('woff2'),
      url('../assets/fonts/SFProText-Regular.woff') format('woff'),
      url('../assets/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-SemiboldItalic.eot');
  src: url('../assets/fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
      url('../assets/fonts/SFProText-SemiboldItalic.woff') format('woff'),
      url('../assets/fonts/SFProText-SemiboldItalic.ttf') format('truetype'),
      url('../assets/fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('../assets/fonts/SFProText-Semibold.eot');
  src: url('../assets/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SFProText-Semibold.woff2') format('woff2'),
      url('../assets/fonts/SFProText-Semibold.woff') format('woff'),
      url('../assets/fonts/SFProText-Semibold.ttf') format('truetype'),
      url('../assets/fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}